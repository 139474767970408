import React, { useEffect, useState, useRef } from 'react';
import { debounce, events, HOST } from './utils';
import './app.css';
import Menu from './Menu';
import Content from './Content';
import { VideoLayer, FormLayer, Button } from './components';
import site_data from './site_data';

let initWinW = window.innerWidth;

let videoPlayInterval = null, welDom = null, top_ph = null;
let inited = false, menuBgTop = 0, flowInterval = null;
const map = (value, min, max, newMin, newMax) => {
  return (value - min) / (max - min) * (newMax - newMin) + newMin;
}
let runningWelCome = (() => {
  let wel_text_dom = null, deg = 0, interval;
  let run = () => {
    if (!wel_text_dom) {
      wel_text_dom = document.querySelector('.wel_text');
    }
    if (!wel_text_dom) {
      return;
    }
    deg+=2;
    if (deg >= 360) {
      deg = 0;
    }
    wel_text_dom.style.backgroundImage = `conic-gradient(from ${deg}deg, #fff, #FEFCD1 40%, #FF9466 50%, #FEFCD1 60%, #fff)`;
  }
  return {
    start () {
      run();
      interval = setInterval(run, 30);
    },
    stop () {
      clearInterval(interval);
    }
  }
})();
// 数据处理
let handMenus = site_data.menus.map((item, index) => {
  let subMenus = item.subMenus.map((subItem, subIndex) => {
    return [subItem[0], subIndex, subItem[1], false];
  });
  return {
    title: item.title,
    subMenus: subMenus,
    id: item.id,
  };
});
const loadImg = (src) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => {
      resolve();
    }
    img.onerror = () => {
      reject();
    }
    img.src = src;
  });
}
let loadedCount = -1, videoReady = false;
let sources = [...JSON.stringify(site_data.content).matchAll(/"pic\d*"\s*:\s*"([^"]*)"/g)].map(match => match[1].trim()).filter(item => !!item);


function App() {
  const [ menuBg, setMenuBg ] = useState(false);
  const [ loadCount, setLoadCount ] = useState(0);
  const [landState, setLandState] = useState(0);
  const [scrolltop, setScrolltop] = useState(0);
  const [size, setWinSize] = useState([window.innerWidth, window.innerHeight]);
  const videoRef = useRef(null);
  const onScroll = (event) => {
    if (inited) {
      setScrolltop(document.documentElement.scrollTop);
      setMenuBg(document.documentElement.scrollTop > menuBgTop);
      events.emit('scroll', document.documentElement.scrollTop);
    }
  }
  
  const onOpenVideo = () => {
    document.documentElement.classList.add('no-scroll');
  },
  onCloseVideo = () => {
    document.documentElement.classList.remove('no-scroll');
  }
  
  useEffect(() => {
    let video;
    clearInterval(videoPlayInterval);
    const onVideoError = (event) => {
      video.removeEventListener('canplay', onCanPlay);
      video.removeEventListener('error', onVideoError);
      console.log('video error');
    }
    const onCanPlay = (event) => {
      console.log('can play');
      video.removeEventListener('canplay', onCanPlay);
      video.removeEventListener('error', onVideoError);
      videoReady = true;
    }
    // onResize 节流函数
    const onResize = debounce(() => {
      setWinSize([window.innerWidth, window.innerHeight]);
      if (window.innerWidth <= 1024) {
        document.documentElement.style.fontSize = '60%';
      } else {
        document.documentElement.style.fontSize = map(window.innerWidth, 480, 1440, 50, 63) + '%';
      }
      if (!welDom) {
        welDom = document.querySelector('.wel_s');
        top_ph = document.querySelector('.top_ph');
      }
      
      let weltop = window.innerHeight * 0.18 + welDom.offsetHeight + 60;
      top_ph.style.height = weltop + 'px';
      let g1 = document.querySelector('.group1 .g1_wrap');
      let g1childs = g1.children;
      // let g1Height = weltop + g1childs[2].offsetHeight + 60; // s0 的margintop
      let g1Height = document.querySelector('.group1').offsetHeight - window.innerHeight;
      let g2Height = document.querySelector('.group2').offsetHeight;
      let g3Height = document.querySelector('.group3').offsetHeight;
      let g4Height = document.querySelector('.group4').offsetHeight;
      let g5Height = document.querySelector('.group5').offsetHeight;
      let g6Height = document.querySelector('.group6').offsetHeight;
      let g7Height = document.querySelector('.group7').offsetHeight;
      let g8Height = document.querySelector('.group8').offsetHeight;
      menuBgTop = weltop + g1childs[1].offsetHeight + 60;
      document.querySelector('.scrollf').style.height = document.querySelector('.main').offsetHeight + 'px';
      events.emit('resize', [
        window.innerWidth, 
        weltop, 
        g1Height,
        g1Height + g2Height,
        g1Height + g2Height + g3Height,
        g1Height + g2Height + g3Height + g4Height,
        g1Height + g2Height + g3Height + g4Height + g5Height,
        g1Height + g2Height + g3Height + g4Height + g5Height + g6Height,
        g1Height + g2Height + g3Height + g4Height + g5Height + g6Height + g7Height,
        g1Height + g2Height + g3Height + g4Height + g5Height + g6Height + g7Height + g8Height,
      ]);
    }, 600);
    
    const onHashChange = (data) => {
      let hash = window.location.hash.replace('#', '');
      events.emit('hash', hash, {from: 'browser'});
    }
    const runInit = () => {
      clearTimeout(flowInterval);
      setLandState(2);
      // 初始化
      onResize();
      flowInterval = setTimeout(() => {
        setLandState(3);
        inited = true;
        // 初始化
        window.scrollTo(0, 0);
        onHashChange();
        console.log('init');
      }, 1000);
    }
    const loadend = () => {
      window.addEventListener('resize', onResize);
      window.addEventListener('hashchange', onHashChange);
      window.addEventListener('scroll', onScroll);
      let hash = window.location.hash.replace('#', '');
      if (videoReady && !hash) {
        video.play();
      //   videoPlayInterval = setInterval(() => {
      //     if (video.currentTime < 3 && video.currentTime + 0.08 >= 3) {
      //       setLandState(2);
      //       // 初始化
      //       // window.scrollTo(0, 0);
      //       // onResize();
      //     }
      //     if (video.currentTime < 8 && video.currentTime + 0.08 >= 8) {
      //       video.currentTime = 8;
      //       runInit();

      //       // setLandState(3);
      //       // inited = true;
      //       // // 初始化
      //       // onHashChange();
      //       // console.log('init');
      //       // clearInterval(videoPlayInterval);
      //     }
      //     video.currentTime += 0.08;
      //   }, 40);
      } else {
        runInit();
      }
    }
    const onVideoUpdate = () => {
      if (video.currentTime < 3 && video.currentTime + 0.5 >= 3) {
        setLandState(2);
      }
      if (video.currentTime < 8 && video.currentTime + 0.5 >= 8) {
        runInit();
      }
    }
    const loaded = () => {
      loadedCount += 1;
      const num = Math.floor(loadedCount / sources.length * 100);
      setLoadCount(num);
      if (num === 100) {
        setTimeout(loadend, 400);
        // loadend();
      }
    }
    if (loadedCount === -1) {
      loadedCount = 0;
      sources.forEach((src) => loadImg(`${HOST}/sh_line/pics/${src}.webp`).then(loaded).catch(loaded));
    }
    
    video = document.querySelector('.land_video');
    video.addEventListener('canplay', onCanPlay);
    video.addEventListener('timeupdate', onVideoUpdate);
    video.addEventListener('error', onVideoError);
    video.currentTime = 0;
    events.addListener('openVideo', onOpenVideo);
    events.addListener('closeVideo', onCloseVideo);
    events.addListener('openForm', onOpenVideo);
    events.addListener('closeForm', onCloseVideo);
    runningWelCome.start();
    return () => {
      runningWelCome.stop();
      clearInterval(videoPlayInterval);
      video?.removeEventListener('canplay', onCanPlay);
      video?.removeEventListener('timeupdate', onVideoUpdate);
      window.removeEventListener('resize', onResize);
      window.removeEventListener('hashchange', onHashChange);
      window.removeEventListener('scroll', onScroll);
      events.removeListener('openVideo', onOpenVideo);
      events.removeListener('closeVideo', onCloseVideo);
      events.removeListener('openForm', onOpenVideo);
      events.removeListener('closeForm', onCloseVideo);
    }
  }, []);
  let cls = ' land_' + landState;
  return (
    <div className='wrap'>
      <div className={'loading_source ' + (loadCount===100?'hide':'')}>
        <div>
          <span>Loading</span>
          <strong>{loadCount}%</strong>
        </div>
      </div>
      <header className={"header " + (menuBg?'has_bg':'')}>
        <div className='header_wrap'>
        <div className="logo"></div>
        <div className="header_btns">
          <a href="https://www.shopline.com/" className="header_link" target='_blank' rel="noreferrer">Shopline.com</a>
          <Button title="Follow us on LinkedIn" link="https://www.linkedin.com/company/shopline/mycompany/"/>
          <Button withBg onTap={() => {
            events.emit('openForm');
          }} title="Get in touch"/>
          <div className='lan_sw'>
            <div>
              <span>EN</span>
              <span>CN</span>
              <span>JP</span>
            </div>
          </div>
          <span className='menu_icon' onClick={() => {
            events.emit('toggleMenu');
          }}>
            <svg width="20" height="18" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.378906" y="0.910156" width="16" height="2" rx="1" fill="white"/>
              <rect x="0.378906" y="6.91016" width="16" height="2" rx="1" fill="white"/>
              <rect x="0.378906" y="12.9102" width="9" height="2" rx="1" fill="white"/>
            </svg>
          </span>
          </div>
        </div>
      </header>
      {initWinW>780?<Menu data={handMenus} type="top"/>:null}
      <Menu data={handMenus}/>
      <div className={'land' + cls}>
        <div className='land_logo'></div>
        <div className='land_text'>2024</div>
        <div className={'wel_s ' + (scrolltop > 40?'hide':'')}>
          <div className='wel_text'>Welcome to<br/>SHOPLINE Horizons 2024</div>
          <div className='wel_meta'>
            Expanding the horizons of commerce with our latest releases —<br/>making it easier, faster, and smarter to build and scale your business.
          </div>
        </div>
        {loadCount===100?<div className="App">
          <div className="main">
            <Content data={site_data.content}/>
          </div>
        </div>:null}
        <video ref={videoRef} className='land_video' playsInline src={`${HOST}/sh_line/animation.webm`} muted></video>
      </div>
      <VideoLayer />
      <FormLayer data={site_data.content.forms}/>
    </div>
  );
}

export default App;
