import React, { useEffect, useState, useRef, memo } from 'react';
import { events, HOST } from '../utils';
const shares = {
    share_mate: 'http://www.facebook.com/sharer.php?u=',
    share_linkin: 'http://www.linkedin.com/shareArticle?mini=true&url=',
}
let id = "";
function Share(props) {
    const [ open, setOpen ] = useState(false);
    const openShare = async (cls) => {
        if (shares[cls]) {
            window.open(`${shares[cls]}${HOST}/#${id}`);
        } else {
            try {
                await navigator.clipboard.writeText(`${HOST}/#${id}`);
            } catch (err) {}
        }
        setOpen(false);
    }
    const onHashChange = (hash) => {
        id = hash;
    }
    useEffect(() => {
        events.addListener('hash', onHashChange);
        return () => {
            events.removeListener('hash', onHashChange);
        }
    }, []);
  return (
    <div className={'share_wrap ' + (open?'open':'')}>
        <div className='share_view' onClick={() => {
            setOpen(!open);
        }}>
            <svg className="svg-circle-border" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <circle cx="50" cy="50" r="48" style={{
                    strokeDasharray: 510,
                    strokeDashoffset: 510
                }} />
            </svg>
            <svg className="svg-circle-border1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <circle cx="50" cy="50" r="48" style={{
                    strokeDasharray: 510,
                    strokeDashoffset: 0
                }} />
            </svg>
            <div className='share_btn'></div>
        </div>
        <div className='share_item_box'>
            <div className='share_items'>
                <div className='share_item share_mate' onClick={() => openShare("share_mate")}></div>
                <div className='share_item share_linkin' onClick={() => openShare("share_linkin")}></div>
                <div className='share_item share_link' onClick={() => openShare("share_link")}></div>
            </div>
        </div>
    </div>
    
  );
}

export default Share;
