import React, { useEffect, useState, useRef, memo } from 'react';
import ReactPlayer from 'react-player'
import { events } from '../utils';

function VideoLayer(props) {
    const [ show, setShow ] = useState(false);
    const [ url, setUrl ] = useState('https://www.youtube.com/watch?v=SKb2LWAnaWA');
    const [ playing, setPlaying ] = useState(false);
    const closeFn = () => {
        events.emit('closeVideo', url);
        setPlaying(false);
        setUrl(null);
        setShow(false);
    }
    const onOpenVideo = (url) => {
        setUrl(url);
        setShow(true);
    }
    useEffect(() => {
        events.addListener('openVideo', onOpenVideo);
        return () => {
            events.removeListener('openVideo', onOpenVideo);
        }
    }, []);
  return (
    <div className={'video_layer ' + (show?'show':'')}>
        <div className='video_player_wrap'>
            <span className='video_player_close' onClick={closeFn}></span>
            <ReactPlayer
                url={url}
                playing={playing}
                style={{position: 'absolute', top: 0, left: 0}}
                width='100%'
                height='100%'
                playsinline
                controls
                config={{
                    youtube: {
                        playerVars: { showinfo: 1 }
                    }
                }}
            />
        </div>
    </div>
  );
}

export default memo(VideoLayer);
