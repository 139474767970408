import React, { useEffect, useState, useRef, memo } from 'react';
import { events, HOST } from '../utils';
import Button from './Button';
function Opa(props) {
  const opa = props.data.opa;
  const [ hstate, setHstate ] = useState(0);
  const [p2, setP2] = useState(new Array(100).fill(0));
  const [p1, setP1] = useState(opa.core.inside.map(item => [item, 0, 0])); 
  const onResize = () => {
    let inWrap = document.querySelector('.opa_in');
    if (inWrap) {
      const radius = inWrap.offsetWidth / 2; // 圆的半径，假设与容器宽度的一半相同
      const centerX = inWrap.offsetWidth / 2;
      const centerY = inWrap.offsetHeight / 2;
      const angleStep = (2 * Math.PI) / p1.length;
      const angleOffset = Math.PI / 6; // 30 度的偏移量，顺时针旋转
      let newP1 = [];
      p1.forEach((item, index) => {
        const angle = -Math.PI / 2 + angleStep * index + angleOffset; // 当前元素的角度

        const x = centerX + radius * Math.cos(angle) - 4; // 4为小圆点的半径
        const y = centerY + radius * Math.sin(angle) - 4;

        newP1.push([item[0], x, y]);
      });
      // console.log(newP1);
      setP1(newP1);
    }
    let newP2 = [];
    const numSegments = p2.length;
    const angleStep = (2 * Math.PI) / numSegments; // Angle between each segment

    for (let i = 0; i < numSegments; i++) {
      const angle = angleStep * i;
      newP2.push(angle);
    }
    setP2(newP2);
  }
  useEffect(() => {
    events.addListener('resize', onResize);
    onResize();
    return () => {
      events.removeListener('resize', onResize);
    }
  }, []);
  return (
    <div className='opa'>
      <div className='sec_padding'>
        <h3>{props.data.title}</h3>
        <h2 className='sec_v_title'>{props.data.subtitle}</h2>
      </div>
      <div className={'opa_wrap opa_s' + hstate}>
        <div className='opa_box'>
          <div className='opa_center'>
            <div className='opa_center_mask'></div>
            <div className='opa_in'>
              <strong>CORE</strong>
              {p1.map((item, index) => {
                let styles = {
                }
                let cls = 'opa_';
                if (index < 3) {
                  styles.left = "20px";
                } else {
                  styles.right = "20px";
                }
                return <div key={index} style={{
                  left: item[1] + 'px',
                  top: item[2] + 'px',
                }}><span style={styles}>{item[0]}</span></div>
              })}
            </div>
          </div>
          <div className='opa_p2' onMouseEnter={() => {
                setHstate(1);
              }}
              onMouseLeave={() => {
                setHstate(0);
              }}
            >
            {p2.map((item, index) => {
              let text = "";
              const strs = {
                25: opa.api[0],
                30: opa.api[1],
                35: opa.api[2],
                40: opa.api[3],
                45: opa.api[4],
                75: opa.api[5],
                80: opa.api[6],
                85: opa.api[7],
                90: opa.api[8],
                95: opa.api[9]
              }
              if (index > 0 && index < 25 || index > 50 && index < 75) {
                return <></>
              }
              if (strs[index]) {
                text = <span className={index>=75?'opa_p2_l':''} style={{transform: `rotate(-${item}rad) ${index>=75?'translateX(-100%)':''}`}}>{strs[index]}</span>;
              }
              return <div key={index} className='opa_pin' style={{transform: `rotate(${item}rad)`}}>{text}<div></div></div>
            })}
            <div className='opa_l_line' style={{transform: `rotate(${p2[14]}rad)`}}>
              <span className='power_block'></span>
              <div className='opa_line_text' style={{transform: `rotate(-${p2[14]}rad)`}}>
                <span></span>
                <div className='opa_line_infos'>
                  <h4>{opa.core.outside.l[0]}</h4>
                  {opa.core.outside.l.slice(1).map(item => <p>{item}</p>)}
                </div>
              </div>
            </div>
            <div className='opa_r_line' style={{transform: `rotate(${p2[64]}rad)`}}>
              <span className='power_block'></span>
              <div className='opa_line_text' style={{transform: `rotate(-${p2[64]}rad)`}}>
                <span></span>
                <div className='opa_line_infos'>
                  <h4>{opa.core.outside.r[0]}</h4>
                  {opa.core.outside.r.slice(1).map(item => <p>{item}</p>)}
                </div>
              </div>
            </div>
            <strong className='opa_title'>Open API</strong>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(Opa);
