import React, { useEffect, useState, useRef, memo } from 'react';
import { events, getOffset } from './utils';
import './app.css';

let isShow = false;
const defaultMenus = [
  {
    title: 'Introduction',
    active: true,
    subMenus: [],
    id: 0,
  },
  {
    title: 'Platform',
    subMenus: [],
    id: 1,
  },
  {
    title: 'Online Store',
    subMenus: [
      ['Overview', 0, '2_overview'],
      ['Store Builder', 1, '2_store_builder'],
      ['SEO Features', 2, '2_seo_features'],
      ['Performance', 3, '2_performance'],
      ['AI', 4, '2_ai'],
      ['Operational Excellence', 5, '2_operational_excellence'],
      ['Product Management', 6, '2_product_management'],
      ['Merchandising', 7, '2_merchandising'],
      ['Promotion Engine', 8, '2_promotion_engine'],
      ['Inventory Management', 9, '2_inventory_management'],
      ['Order Management', 10, '2_order_management'],
      ['Checkout & Payment', 11, '2_checkout_payment'],
      ['Analytics', 12, '2_analytics'],
      ['Success Story: Peter Jackson', 13, '2_success_story'],
    ],
    id: 2,
  },
  {
    title: 'Elevated Shopping Experiences',
    subMenus: [
      ['Overview', 0, '3_overview'],
      ['Retail POS', 1, '3_retail_pos', 1],
      ['Mobile Experience', 2, '3_mobile_experience'],
      ['Multichannel Connect', 3, '3_multichannel_connect'],
      ['Success Story: Sunnystep', 4, '3_success_story'],
    ],
    id: 3,
  },
  {
    title: 'Meaningful Engagements',
    subMenus: [
      ['Overview', 0, '4_overview'],
      ['Customer Intelligence', 1, '4_customer_intelligence'],
      ['Hyper-personalization', 2, '4_hyper_personalization'],
      ['Analytics', 3, '4_analytics'],
    ],
    id: 4,
  },
  {
    title: 'Social Commerce',
    subMenus: [
      ['Overview', 0, '5_overview'],
      ['Social Ads', 1, '5_social_ads'],
      ['Affiliate Marketing', 2, '5_affiliate_marketing'],
      ['TikTok Shop', 3, '5_tiktok_shop'],
      ['Live Selling', 4, '5_live_selling'],
      ['Chat Commerce', 5, '5_chat_commerce'],
    ],
    id: 5,
  },
  {
    title: 'Extensibility',
    subMenus: [
      ['Dev Tools', 0, '6_dev_tools'],
      ['APIs', 1, '6_apis'],
      ['Integrations', 2, '6_integrations'],
      ['App Store', 3, '6_app_store'],
      ['Strategic Partners', 4, '6_strategic_partners'],
    ],
    id: 6,
  },
  {
    title: 'Reaching New Markets',
    subMenus: [],
    id: 7,
  },
  {
    title: 'B2B',
    subMenus: [],
    id: 8,
  },
];
let menuWrapHeight = 0;
let menuMinTop = -1;
let winH = window.innerHeight;
let winW = window.innerWidth;
function Menu({ data, type }) {
  let isTop = type === 'top';
  let [ show, setShow ] = useState(false);
  let [ topMenuShow, setTopMenuShow ] = useState(false);
  let [menus, setMenus] = useState(data);
  let [menuActive, setMenuActive] = useState([0, 0]); // 当前hash的index, 父菜单的index
  let [top, setTop] = useState(isTop?0:winH);
  let [ left, setLeft ] = useState(isTop?0:16);
  let [height, setHeight] = useState(winH-60);
  const onScroll = (scrollTop) => {
    if (winW <= 1024) {
      return;
    }
    if (scrollTop <= menuMinTop) {
      setTop(isTop?0:winH);
      document.querySelector('.scrollf').style.left = "0";
      setTopMenuShow(true);
    } else {
      !isTop&&setTop(Math.max(menuMinTop + winH - scrollTop, 0));
      document.querySelector('.scrollf').style.left = "240px";
      setTopMenuShow(false);
    }
  }
  const onToggleMenu = () => {
    isShow = !isShow;
    setShow(isShow);
    if (winW <= 1024) {
      document.documentElement.classList[isShow?'add':'remove']('no-scroll');
    }
  }
  const onResize = ([width, height, g1Height]) => {
    
    winH = window.innerHeight;
    winW = window.innerWidth;
    menuWrapHeight = document.querySelector(isTop?'.top_menu':'.content_menu').offsetHeight;
    if (winW <= 1024) {
      setHeight(winH-60);
      setTop(0);
      setLeft(0);
      return;
    }
    if (!isTop) {
      if (winW <= 1440) {
        setLeft(16);
      } else {
        setLeft((winW - 1440) / 2 + (isTop?0:16));
      }
    }
    // if (winW <= 1440) {
    //   setLeft(isTop?0:16);
    // } else {
    //   setLeft((winW - 1440) / 2 + (isTop?0:16));
    // }
    isShow = false;
    setShow(isShow);
    setHeight(winH-60);
    menuMinTop = g1Height;
    setTop(isTop?0:winH);
    onScroll(document.documentElement.scrollTop);
  }
  const onHashChange = (hash, data) => {
    let newMenus = [];
    menus.forEach((menu, index) => {
      let newMenu = {...menu};
      if ('c_'+newMenu.id === hash) {
        setMenuActive([index, index]);
      };
      newMenu.subMenus.forEach((subMenu) => {
        if ('c_'+subMenu[2] === hash) {
          setMenuActive([index, index]);
          subMenu[3] = true;
        } else {
          subMenu[3] = false;
        }
      });
      newMenus.push(newMenu);
    });
    setMenus(newMenus);
  }

  useEffect(() => {
    isShow = false;
    events.addListener('resize', onResize);
    events.addListener('scroll', onScroll);
    !isTop&&events.addListener('toggleMenu', onToggleMenu);
    events.addListener('hash', onHashChange);
    return () => {
      events.removeListener('resize', onResize);
      events.removeListener('scroll', onScroll);
      events.removeListener('hash', onHashChange);
      !isTop&&events.removeListener('toggleMenu', onToggleMenu);
    }
  }, []);
  return (
    <div className={["menu",(show?'show':''),isTop?'top_menu':'content_menu',!topMenuShow&&isTop?'hide':''].join(" ")} style={{transform: `translate3d(0, ${top}px, 0)`, left: left + 'px', height: `${height}px`}}>
      {isTop?<div className='top_menu_btn'>
        <span>Menu</span>
      </div>:null}
      <div className='menu_box'>
      <div className='menu_ticon'></div>
      {menus.map((menu, index) => {
        if (menu.subMenus.length > 0) {
          let subItems = <ul>
              {menu.subMenus.map((subMenu, subIndex) => {
                return (
                  <li onClick={() => {
                    window.history.pushState({from: 'menu'}, '', '#c_'+subMenu[2]);
                    events.emit('hash', 'c_'+subMenu[2], {from: 'menu'});
                    document.documentElement.classList.remove('no-scroll');
                    isShow = false;
                    setShow(isShow);
                  }} key={`${index}_${subIndex}`} className={subMenu[3]?'active':''}><a href="javascript:;">{subMenu[0]}</a></li>
                );
              })}
            </ul>;
          let subMenu = <div key={index+'_0'} className={'menu_wrap' + (menuActive[1]===index?' active':'')}>
              {subItems}
            </div>;
          return [<a key={index+'_1'} href="javascript:;" onClick={() => {
            if (isTop) {
              setMenuActive([menuActive[0], index]);
              setTimeout(() => {
                window.history.pushState({from: 'menu'}, '', '#c_'+menu.subMenus[0][2]);
                events.emit('hash', 'c_'+menu.subMenus[0][2], {from: 'menu'});
              }, 1000);
              return;
            }
            document.documentElement.classList.remove('no-scroll');
            // setMenuActive([menuActive[0], index]);
            isShow = false;
            setShow(isShow);
            window.history.pushState({from: 'menu'}, '', '#c_'+menu.subMenus[0][2]);
            events.emit('hash', 'c_'+menu.subMenus[0][2], {from: 'menu'});
          }} className={'menu_a' + (menuActive[1]===index?' menu_max':' menu_min')}>{menu.title}</a>,subMenu];
        } else {
          return <a key={index+'_2'} onClick={() => {
            window.history.pushState({from: 'menu'}, '', '#c_' + menu.id);
            events.emit('hash', 'c_' + menu.id, {from: 'menu'});
            document.documentElement.classList.remove('no-scroll');
            isShow = false;
            setShow(isShow);
          }} href="javascript:;" className={'menu_a nosubmenu' + (menuActive[0]===index?' menu_max':' menu_min')}>{menu.title}</a>;
        }
      })}
      {/* <a href="javascript:;" className='menu_a'>Introduction</a>
      <a href="javascript:;" className='menu_a'>Platform</a>
      <a href="javascript:;" className='menu_a menu_max'>Online Store</a>
      <div className='menu_wrap'>
        <ul>
          <li><a href="javascript:;">Overview</a></li>
          <li><a href="javascript:;">Store Builder</a></li>
          <li><a href="javascript:;">SEO Features</a></li>
          <li><a href="javascript:;">Performance</a></li>
          <li><a href="javascript:;">AI</a></li>
          <li><a href="javascript:;">Operational Excellence</a></li>
          <li><a href="javascript:;">Product Management</a></li>
          <li><a href="javascript:;">Merchandising</a></li>
          <li><a href="javascript:;">Promotion Engine</a></li>
          <li><a href="javascript:;">Inventory Management</a></li>
          <li><a href="javascript:;">Order Management</a></li>
          <li><a href="javascript:;">Checkout & Payment</a></li>
          <li><a href="javascript:;">Analytics</a></li>
          <li><a href="javascript:;">Success Story: Peter Jackson</a></li>
        </ul>
      </div>
      <a href="javascript:;" className='menu_a'>Elevated Shopping Experiences</a>
      <div className='menu_wrap'>
        <ul>
          <li><a href="javascript:;">Overview</a></li>
          <li><a href="javascript:;">Retail POS</a></li>
          <li><a href="javascript:;">Mobile Experience</a></li>
          <li><a href="javascript:;">Multichannel Connect</a></li>
          <li><a href="javascript:;">Success Story: Sunnystep</a></li>
        </ul>
      </div>
      <a href="javascript:;" className='menu_a'>Meaningful Engagements</a>
      <div className='menu_wrap'>
        <ul>
          <li><a href="javascript:;">Overview</a></li>
          <li><a href="javascript:;">Customer Intelligence</a></li>
          <li><a href="javascript:;">Hyper-personalization</a></li>
          <li><a href="javascript:;">Analytics</a></li>
        </ul>
      </div>
      <a href="javascript:;" className='menu_a'>Social Commerce</a>
      <div className='menu_wrap'>
        <ul>
          <li><a href="javascript:;">Overview</a></li>
          <li><a href="javascript:;">Social Ads</a></li>
          <li><a href="javascript:;">Affiliate Marketing</a></li>
          <li><a href="javascript:;">TikTok Shop</a></li>
          <li><a href="javascript:;">Live Selling</a></li>
          <li><a href="javascript:;">Chat Commerce</a></li>
        </ul>
      </div>
      <a href="javascript:;" className='menu_a'>Extensibility</a>
      <div className='menu_wrap'>
        <ul>
          <li><a href="javascript:;">Dev Tools</a></li>
          <li><a href="javascript:;">APIs</a></li>
          <li><a href="javascript:;">Integrations</a></li>
          <li><a href="javascript:;">App Store</a></li>
          <li><a href="javascript:;">Strategic Partners</a></li>
        </ul>
      </div>
      <a href="javascript:;" className='menu_a'>Reaching New Markets</a>
      <a href="javascript:;" className='menu_a'>B2B</a> */}
      <div className='menu_bicon'></div>
      </div>
    </div>
  );
}

export default memo(Menu);
