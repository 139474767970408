import React, { useEffect, useState, useRef, memo } from 'react';
import { events } from '../utils';
let resizeTimer = null;
function Button(props) {
    const { title, withBg, link, onTap, loading } = props;
    const titleRef = useRef(null);
    const rectRef = useRef(null);
    const [ btnSize, setBtnSize ] = useState([0, 0]);
    // const [lh, setLh] = useState(36);
    const [ svgValues, setSvgValues ] = useState(['210','50','0 0 220 60']);
    const onResize = () => {
        if (titleRef.current) {
            const textWidth = titleRef.current.offsetWidth;
            const buttonHeight = titleRef.current.offsetHeight;
            const lh = titleRef.current.children[0].offsetHeight;
            // setLh(lh);
            // 计算边框周长
            const perimeter = 2 * textWidth + 1.3 * buttonHeight;
            setSvgValues([
                textWidth, buttonHeight, `0 0 ${textWidth + 20} ${buttonHeight + 10}`, perimeter
            ])
        }
        if (withBg) {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                if (rectRef.current) {
                    let bbox = rectRef.current.getBoundingClientRect();
                    setBtnSize([bbox.width, bbox.height]);
                }
            }, 100);
        }
    }
    useEffect(() => {
        events.addListener('resize', onResize);
        return () => {
            events.removeListener('resize', onResize);
        }
    }, []);
  return (
    // <div className='abtn'>
    //     <div className='abtn_title'>{title}<div><span><b></b><b></b><b></b></span></div></div>
    //     <svg width="100%" height="100%" viewBox="0 0 135 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <path d="M0.875 18.4414C0.875 8.70739 8.76598 0.816406 18.5 0.816406H116.5C126.234 0.816406 134.125 8.70739 134.125 18.4414C134.125 28.1754 126.234 36.0664 116.5 36.0664H18.5C8.76598 36.0664 0.875 28.1754 0.875 18.4414Z" stroke="#666F9A" stroke-width="0.75"/>
    //         <path d="M0.875 18.4414C0.875 8.70739 8.76598 0.816406 18.5 0.816406H116.5C126.234 0.816406 134.125 8.70739 134.125 18.4414C134.125 28.1754 126.234 36.0664 116.5 36.0664H18.5C8.76598 36.0664 0.875 28.1754 0.875 18.4414Z" stroke="#ffffff" stroke-width="0.75"/>
    //     </svg>
    // </div>
    <div className={'btn1 ' + (loading?'loading':'')} onClick={(e) => {
        if (loading) return;
        onTap && onTap(e);
        if (link) {
            if (link === "form") {
                events.emit('openForm');
                return;
            }
            window.open(link);
        }
    }}>
        <div className='btn1_loading'></div>
        <div className="btn1_title" ref={titleRef}>
            <div className='btn1_title_text'>
                {title}
                {/* <div style={{lineHeight: lh + 'px'}}>
                    <span>{title}</span>
                    <span>{title}</span>
                </div> */}
            </div>
            
        <div className='btn_arr'><span><b></b><b></b><b></b></span></div></div>
        <svg className="btn1_svg_border" xmlns="http://www.w3.org/2000/svg" viewBox={svgValues[2]} preserveAspectRatio="none">
            <rect x="5" y="5" rx="22" ry="22" width={svgValues[0]} height={svgValues[1]} style={{
                strokeDasharray: svgValues[3],
                strokeDashoffset: svgValues[3]
            }} />
        </svg>
        <svg className="btn1_svg_border1" xmlns="http://www.w3.org/2000/svg" viewBox={svgValues[2]} preserveAspectRatio="none">
            <rect ref={rectRef} x="5" y="5" rx="22" ry="22" strokeOpacity="0.5" width={svgValues[0]} height={svgValues[1]} style={{
                strokeDasharray: svgValues[3],
                strokeDashoffset: 0
            }} />
        </svg>
        {withBg && <div style={{width: btnSize[0] + 'px', height: btnSize[1] + 'px'}} className='btn1_bg'></div>}
    </div>
    // <div className='btn'>
    //     <div className='btn_title'>{title}</div>
    //     <div className='btn_arr'><span><b></b><b></b><b></b></span></div>
    //     <div className='btn_ply'>{title}</div>
    // </div>
  );
}

export default Button;
