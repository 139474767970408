import React, { useEffect, useState, useRef, memo } from 'react';
import { Button } from './';
import { events, parseContent } from '../utils';

function FormLayer(props) {
    const data = props.data;
    const formRef = useRef(null);
    const [ success, setSuccess ] = useState(false);
    const [ show, setShow ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ values, setValues ] = useState({
        Name_First: '',
        Name_Last: '',
        Email: '',
        SingleLine: '',
        Dropdown: '',
        PhoneNumber_countrycode: '',
        Dropdown1: '',
        SingleLine1: '',
        Checkbox: [],
    });
    const [ errors, setErrors ] = useState({
        Name_First: '',
        Name_Last: '',
        Email: '',
        SingleLine: '',
        SingleLine1: '',
    });
    const onSubmit = async () => {
        if (values.Name_First.trim() === '' || values.Name_First.trim().length > 255) {
            setErrors({...errors, Name_First: 'Please complete this required field'});
            return;
        }
        if (values.Name_Last.trim() === '' || values.Name_Last.trim().length > 255) {
            setErrors({...errors, Name_Last: 'Please complete this required field'});
            return;
        }
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(values.Email)) {
            setErrors({...errors, Email: 'Please complete this required field'});
            return;
        }
        if (values.SingleLine.trim() === '' || values.SingleLine.trim().length > 255) {
            setErrors({...errors, SingleLine: 'Please complete this required field'});
            return;
        }
        if (values.SingleLine1.trim() === '' || values.SingleLine1.trim().length > 255) {
            setErrors({...errors, SingleLine1: 'Please complete this required field'});
            return;
        }
        const formData = new FormData(formRef.current);
        try {
            setLoading(true);
            const response = await fetch('https://forms.zohopublic.com/tonylinshop1/form/shopline/formperma/cZ-QrZGr0cceys4WNDFwe-UrWfRJra4RhuqpSZss4lw/htmlRecords/submit', {
              method: 'POST',
              body: formData, // 直接发送 FormData
              mode: 'no-cors',
            });
            if (response) {
                setSuccess(true);
            }
          } catch (error) {
            console.error('Error:', error);
          } finally {
            setLoading(false);
          }
    }
    const onChange = (e) => {
        const { name, value, type, checked, id } = e.target;
        if (type === 'checkbox') {
            let newCheckbox = values.Checkbox.slice();
            let index = id.split('_')[1] * 1 - 1;
            newCheckbox[index] = checked?value:null;
            setValues({...values, Checkbox: newCheckbox});
            return;
        }
        if (errors[name]) {
            setErrors({...errors, [name]: ''});
        }
        setValues({...values, [name]: value}); 
    }
    const closeFn = () => {
        events.emit('closeForm');
        setShow(false);
    }
    const onOpenForm = () => {
        setShow(true);
    }
    useEffect(() => {
        events.addListener('openForm', onOpenForm);
        return () => {
            events.removeListener('openForm', onOpenForm);
        }
    }, []);
  let cls = ['form_view'];
    if (show) {
        cls.push('show');
    }
    if (success) {
        cls.push('success');
    }
  return (
    <div className={cls.join(" ")}>
        <span className='form_close' onClick={closeFn}></span>
        <div className='form_success'>
            <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M47.5 24.543C47.5 37.5217 36.9787 48.043 24 48.043C11.0213 48.043 0.5 37.5217 0.5 24.543C0.5 11.5643 11.0213 1.04297 24 1.04297C36.9787 1.04297 47.5 11.5643 47.5 24.543Z" stroke="white"/>
                <path d="M12.5547 24.2847L20.3557 32.0855L35.4451 16.9961" stroke="white" strokeWidth="3"/>
            </svg>
            <h2>Thank you!</h2>
            <p>{data.success}</p>
        </div>
        <div className='form_wrap sec_padding'>
            <h2>{parseContent(data.title)}</h2>
            <div className='forms'>
                <form ref={formRef}>
                    <div className='form_inps_wrap'>
                        <div className='input_item'>
                            <input type='text' value={values.Name_First} onChange={onChange} placeholder={data.fields.Name_First} maxLength="255" name="Name_First"/>
                            <span className={'form_tips ' + (errors.Name_First?'show':'')}>{errors.Name_First}</span>
                        </div>
                        <div className='input_item'>
                            <input type='text' value={values.Name_Last} onChange={onChange} placeholder={data.fields.Name_Last} maxLength="255" name="Name_Last"/>
                            <span className={'form_tips ' + (errors.Name_Last?'show':'')}>{errors.Name_Last}</span>
                        </div>
                        <div className='input_item require'>
                            <input type='text' value={values.Email} onChange={onChange} placeholder={data.fields.Email} maxLength="255" name="Email"/>
                            <span className={'form_tips ' + (errors.Email?'show':'')}>{errors.Email}</span>
                        </div>
                        <div className='input_item require'>
                            <input type='text' value={values.SingleLine} onChange={onChange} placeholder={data.fields.Company} name="SingleLine" maxLength="255"/>
                            <span className={'form_tips ' + (errors.SingleLine?'show':'')}>{errors.SingleLine}</span>
                        </div>
                        <div className='input_item select_item'>
                            <select name="Dropdown" value={values.Dropdown} onChange={onChange}>
                                <option value="-Select-">{data.fields.Annual_Sales}</option>
                                <option value="0&#x20;-&#x20;500k&#x20;USD">0 - 500k USD</option>
                                <option value="500k&#x20;-&#x20;1&#x20;million&#x20;USD">500k - 1 million USD</option>
                                <option value="1&#x20;million&#x20;-&#x20;50&#x20;million&#x20;USD">1 million - 50 million USD</option>
                                <option value="Over&#x20;50&#x20;million&#x20;USD">Over 50 million USD</option>
                            </select>
                            <span></span>
                        </div>
                        <div className='input_item'>
                            <input type='text' value={values.PhoneNumber_countrycode} onChange={onChange} placeholder={data.fields.Phone_Number} compname="PhoneNumber" name="PhoneNumber_countrycode" maxLength="20" id="international_PhoneNumber_countrycode"/>
                        </div>
                        <div className='input_item select_item'>
                            <select name="Dropdown1" value={values.Dropdown1} onChange={onChange}>
                                <option value="-Select-">{data.fields.Country}</option>
                                <option value="United&#x20;States">United States</option>
                                <option value="China">China</option>
                                <option value="Japan">Japan</option>
                                <option value="Hong&#x20;Kong">Hong Kong</option>
                                <option value="Singapore">Singapore</option>
                                <option value="Malaysia">Malaysia</option>
                                <option value="Vietnam">Vietnam</option>
                                <option value="Thailand">Thailand</option>
                                <option value="Australia">Australia</option>
                                <option value="United&#x20;Kingdom">United Kingdom</option>
                                <option value="Afghanistan">Afghanistan</option>
                                <option value="&Aring;land&#x20;Islands">&Aring;land Islands</option>
                                <option value="Albania">Albania</option>
                                <option value="Algeria">Algeria</option>
                                <option value="American&#x20;Samoa">American Samoa</option>
                                <option value="Andorra">Andorra</option>
                                <option value="Angola">Angola</option>
                                <option value="Anguilla">Anguilla</option>
                                <option value="Antarctica">Antarctica</option>
                                <option value="Antigua&#x20;and&#x20;Barbuda">Antigua and Barbuda</option>
                                <option value="Argentina">Argentina</option>
                                <option value="Armenia">Armenia</option>
                                <option value="Aruba">Aruba</option>
                                <option value="Ascension&#x20;Island">Ascension Island</option>
                                <option value="Austria">Austria</option>
                                <option value="Azerbaijan">Azerbaijan</option>
                                <option value="Bahamas">Bahamas</option>
                                <option value="Bahrain">Bahrain</option>
                                <option value="Bangladesh">Bangladesh</option>
                                <option value="Barbados">Barbados</option>
                                <option value="Belarus">Belarus</option>
                                <option value="Belgium">Belgium</option>
                                <option value="Belize">Belize</option>
                                <option value="Benin">Benin</option>
                                <option value="Bermuda">Bermuda</option>
                                <option value="Bhutan">Bhutan</option>
                                <option value="Bolivia">Bolivia</option>
                                <option value="Bosnia&#x20;and&#x20;Herzegovina">Bosnia and Herzegovina</option>
                                <option value="Botswana">Botswana</option>
                                <option value="Bouvet&#x20;Island">Bouvet Island</option>
                                <option value="Brazil">Brazil</option>
                                <option value="British&#x20;Indian&#x20;Ocean&#x20;Territory">British Indian Ocean Territory</option>
                                <option value="British&#x20;Virgin&#x20;Islands">British Virgin Islands</option>
                                <option value="Brunei">Brunei</option>
                                <option value="Bulgaria">Bulgaria</option>
                                <option value="Burkina&#x20;Faso">Burkina Faso</option>
                                <option value="Burundi">Burundi</option>
                                <option value="Cambodia">Cambodia</option>
                                <option value="Cameroon">Cameroon</option>
                                <option value="Canada">Canada</option>
                                <option value="Cape&#x20;Verde">Cape Verde</option>
                                <option value="Caribbean&#x20;Netherlands">Caribbean Netherlands</option>
                                <option value="Cayman&#x20;Islands">Cayman Islands</option>
                                <option value="Central&#x20;African&#x20;Republic">Central African Republic</option>
                                <option value="Chad">Chad</option>
                                <option value="Chile">Chile</option>
                                <option value="Christmas&#x20;Island">Christmas Island</option>
                                <option value="Cocos&#x20;&#x28;Keeling&#x29;&#x20;Islands">Cocos &#x28;Keeling&#x29; Islands</option>
                                <option value="Colombia">Colombia</option>
                                <option value="Comoros">Comoros</option>
                                <option value="Cook&#x20;Islands">Cook Islands</option>
                                <option value="Costa&#x20;Rica">Costa Rica</option>
                                <option value="Croatia">Croatia</option>
                                <option value="Cuba">Cuba</option>
                                <option value="Cura&ccedil;ao">Cura&ccedil;ao</option>
                                <option value="Cyprus">Cyprus</option>
                                <option value="Czech&#x20;Republic">Czech Republic</option>
                                <option value="Democratic&#x20;Republic&#x20;of&#x20;the&#x20;Congo">Democratic Republic of the Congo</option>
                                <option value="Denmark">Denmark</option>
                                <option value="Djibouti">Djibouti</option>
                                <option value="Dominica">Dominica</option>
                                <option value="Dominican&#x20;Republic">Dominican Republic</option>
                                <option value="Ecuador">Ecuador</option>
                                <option value="Egypt">Egypt</option>
                                <option value="El&#x20;Salvador">El Salvador</option>
                                <option value="Equatorial&#x20;Guinea">Equatorial Guinea</option>
                                <option value="Eritrea">Eritrea</option>
                                <option value="Estonia">Estonia</option>
                                <option value="Eswatini">Eswatini</option>
                                <option value="Ethiopia">Ethiopia</option>
                                <option value="Falkland&#x20;Islands">Falkland Islands</option>
                                <option value="Faroe&#x20;Islands">Faroe Islands</option>
                                <option value="Fiji">Fiji</option>
                                <option value="Finland">Finland</option>
                                <option value="France">France</option>
                                <option value="French&#x20;Guiana">French Guiana</option>
                                <option value="French&#x20;Polynesia">French Polynesia</option>
                                <option value="French&#x20;Southern&#x20;and&#x20;Antarctic&#x20;Lands">French Southern and Antarctic Lands</option>
                                <option value="Gabon">Gabon</option>
                                <option value="Gambia">Gambia</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Germany">Germany</option>
                                <option value="Ghana">Ghana</option>
                                <option value="Gibraltar">Gibraltar</option>
                                <option value="Greece">Greece</option>
                                <option value="Greenland">Greenland</option>
                                <option value="Grenada">Grenada</option>
                                <option value="Guadeloupe">Guadeloupe</option>
                                <option value="Guam">Guam</option>
                                <option value="Guatemala">Guatemala</option>
                                <option value="Guernsey">Guernsey</option>
                                <option value="Guinea">Guinea</option>
                                <option value="Guinea-Bissau">Guinea-Bissau</option>
                                <option value="Guyana">Guyana</option>
                                <option value="Haiti">Haiti</option>
                                <option value="Heard&#x20;Island&#x20;and&#x20;McDonald&#x20;Islands">Heard Island and McDonald Islands</option>
                                <option value="Honduras">Honduras</option>
                                <option value="Hungary">Hungary</option>
                                <option value="Iceland">Iceland</option>
                                <option value="India">India</option>
                                <option value="Indonesia">Indonesia</option>
                                <option value="Iran">Iran</option>
                                <option value="Iraq">Iraq</option>
                                <option value="Ireland">Ireland</option>
                                <option value="Isle&#x20;of&#x20;Man">Isle of Man</option>
                                <option value="Israel">Israel</option>
                                <option value="Italy">Italy</option>
                                <option value="Ivory&#x20;Coast">Ivory Coast</option>
                                <option value="Jamaica">Jamaica</option>
                                <option value="Jersey">Jersey</option>
                                <option value="Jordan">Jordan</option>
                                <option value="Kazakhstan">Kazakhstan</option>
                                <option value="Kenya">Kenya</option>
                                <option value="Kiribati">Kiribati</option>
                                <option value="Kuwait">Kuwait</option>
                                <option value="Kyrgyzstan">Kyrgyzstan</option>
                                <option value="Laos">Laos</option>
                                <option value="Latvia">Latvia</option>
                                <option value="Lebanon">Lebanon</option>
                                <option value="Lesotho">Lesotho</option>
                                <option value="Liberia">Liberia</option>
                                <option value="Libya">Libya</option>
                                <option value="Liechtenstein">Liechtenstein</option>
                                <option value="Lithuania">Lithuania</option>
                                <option value="Luxembourg">Luxembourg</option>
                                <option value="Macau">Macau</option>
                                <option value="Madagascar">Madagascar</option>
                                <option value="Malawi">Malawi</option>
                                <option value="Maldives">Maldives</option>
                                <option value="Mali">Mali</option>
                                <option value="Malta">Malta</option>
                                <option value="Marshall&#x20;Islands">Marshall Islands</option>
                                <option value="Martinique">Martinique</option>
                                <option value="Mauritania">Mauritania</option>
                                <option value="Mauritius">Mauritius</option>
                                <option value="Mayotte">Mayotte</option>
                                <option value="Mexico">Mexico</option>
                                <option value="Micronesia">Micronesia</option>
                                <option value="Moldova">Moldova</option>
                                <option value="Monaco">Monaco</option>
                                <option value="Mongolia">Mongolia</option>
                                <option value="Montenegro">Montenegro</option>
                                <option value="Montserrat">Montserrat</option>
                                <option value="Morocco">Morocco</option>
                                <option value="Mozambique">Mozambique</option>
                                <option value="Myanmar">Myanmar</option>
                                <option value="Namibia">Namibia</option>
                                <option value="Nauru">Nauru</option>
                                <option value="Nepal">Nepal</option>
                                <option value="Netherlands">Netherlands</option>
                                <option value="Netherlands&#x20;Antilles&#x20;&#x28;&#x5df2;&#x89e3;&#x6563;&#x29;">Netherlands Antilles &#x28;&#x5df2;&#x89e3;&#x6563;&#x29;</option>
                                <option value="New&#x20;Caledonia">New Caledonia</option>
                                <option value="New&#x20;Zealand">New Zealand</option>
                                <option value="Nicaragua">Nicaragua</option>
                                <option value="Niger">Niger</option>
                                <option value="Nigeria">Nigeria</option>
                                <option value="Niue">Niue</option>
                                <option value="Norfolk&#x20;Island">Norfolk Island</option>
                                <option value="North&#x20;Korea">North Korea</option>
                                <option value="North&#x20;Macedonia">North Macedonia</option>
                                <option value="Northern&#x20;Mariana&#x20;Islands">Northern Mariana Islands</option>
                                <option value="Norway">Norway</option>
                                <option value="Oman">Oman</option>
                                <option value="Pakistan">Pakistan</option>
                                <option value="Palau">Palau</option>
                                <option value="Palestine">Palestine</option>
                                <option value="Panama">Panama</option>
                                <option value="Papua&#x20;New&#x20;Guinea">Papua New Guinea</option>
                                <option value="Paraguay">Paraguay</option>
                                <option value="Peru">Peru</option>
                                <option value="Philippines">Philippines</option>
                                <option value="Pitcairn&#x20;Islands">Pitcairn Islands</option>
                                <option value="Poland">Poland</option>
                                <option value="Portugal">Portugal</option>
                                <option value="Puerto&#x20;Rico">Puerto Rico</option>
                                <option value="Qatar">Qatar</option>
                                <option value="Republic&#x20;of&#x20;the&#x20;Congo">Republic of the Congo</option>
                                <option value="R&eacute;union">R&eacute;union</option>
                                <option value="Romania">Romania</option>
                                <option value="Russia">Russia</option>
                                <option value="Rwanda">Rwanda</option>
                                <option value="Saint&#x20;Barth&eacute;lemy">Saint Barth&eacute;lemy</option>
                                <option value="Saint&#x20;Helena">Saint Helena</option>
                                <option value="Ascension&#x20;and&#x20;Tristan&#x20;da&#x20;Cunha">Ascension and Tristan da Cunha</option>
                                <option value="Saint&#x20;Kitts&#x20;and&#x20;Nevis">Saint Kitts and Nevis</option>
                                <option value="Saint&#x20;Lucia">Saint Lucia</option>
                                <option value="Saint&#x20;Martin&#x20;&#x28;France&#x29;">Saint Martin &#x28;France&#x29;</option>
                                <option value="Saint&#x20;Pierre&#x20;and&#x20;Miquelon">Saint Pierre and Miquelon</option>
                                <option value="Saint&#x20;Vincent&#x20;and&#x20;the&#x20;Grenadines">Saint Vincent and the Grenadines</option>
                                <option value="Samoa">Samoa</option>
                                <option value="San&#x20;Marino">San Marino</option>
                                <option value="Sao&#x20;Tome&#x20;and&#x20;Principe">Sao Tome and Principe</option>
                                <option value="Saudi&#x20;Arabia">Saudi Arabia</option>
                                <option value="Senegal">Senegal</option>
                                <option value="Serbia">Serbia</option>
                                <option value="Seychelles">Seychelles</option>
                                <option value="Sierra&#x20;Leone">Sierra Leone</option>
                                <option value="Sint&#x20;Maarten">Sint Maarten</option>
                                <option value="Slovakia">Slovakia</option>
                                <option value="Slovenia">Slovenia</option>
                                <option value="Solomon&#x20;Islands">Solomon Islands</option>
                                <option value="Somalia">Somalia</option>
                                <option value="South&#x20;Africa">South Africa</option>
                                <option value="South&#x20;Georgia&#x20;and&#x20;the&#x20;South&#x20;Sandwich&#x20;Islands">South Georgia and the South Sandwich Islands</option>
                                <option value="South&#x20;Korea">South Korea</option>
                                <option value="South&#x20;Sudan">South Sudan</option>
                                <option value="Spain">Spain</option>
                                <option value="Sri&#x20;Lanka">Sri Lanka</option>
                                <option value="Sudan">Sudan</option>
                                <option value="Suriname">Suriname</option>
                                <option value="Svalbard&#x20;and&#x20;Jan&#x20;Mayen">Svalbard and Jan Mayen</option>
                                <option value="Sweden">Sweden</option>
                                <option value="Switzerland">Switzerland</option>
                                <option value="Syria">Syria</option>
                                <option value="Taiwan">Taiwan</option>
                                <option value="Tajikistan">Tajikistan</option>
                                <option value="Tanzania">Tanzania</option>
                                <option value="Timo">Timo</option>
                                <option value="Togo">Togo</option>
                                <option value="Tokelau">Tokelau</option>
                                <option value="Tonga">Tonga</option>
                                <option value="Trinidad&#x20;and&#x20;Tobago">Trinidad and Tobago</option>
                                <option value="Tristan&#x20;da&#x20;Cunha">Tristan da Cunha</option>
                                <option value="Tunisia">Tunisia</option>
                                <option value="Turkey">Turkey</option>
                                <option value="Turkmenistan">Turkmenistan</option>
                                <option value="Turks&#x20;and&#x20;Caicos&#x20;Islands">Turks and Caicos Islands</option>
                                <option value="Tuvalu">Tuvalu</option>
                                <option value="U.S.&#x20;Virgin&#x20;Islands">U.S. Virgin Islands</option>
                                <option value="Uganda">Uganda</option>
                                <option value="Ukraine">Ukraine</option>
                                <option value="United&#x20;Arab&#x20;Emirates">United Arab Emirates</option>
                                <option value="United&#x20;States&#x20;Minor&#x20;Outlying&#x20;Islands">United States Minor Outlying Islands</option>
                                <option value="Uruguay">Uruguay</option>
                                <option value="Uzbekistan">Uzbekistan</option>
                                <option value="Vanuatu">Vanuatu</option>
                                <option value="Vatican">Vatican</option>
                                <option value="Venezuela">Venezuela</option>
                                <option value="Wallis&#x20;and&#x20;Futuna">Wallis and Futuna</option>
                                <option value="Western&#x20;Sahara">Western Sahara</option>
                                <option value="Yemen">Yemen</option>
                                <option value="Zambia">Zambia</option>
                                <option value="Zimbabwe">Zimbabwe</option>
                            </select>
                            <span></span>
                        </div>
                        <div className='input_item require'>
                            <input type="text" value={values.SingleLine1} onChange={onChange} name="SingleLine1" maxLength="255" placeholder={data.fields.Role} />
                            <span className={'form_tips ' + (errors.SingleLine1?'show':'')}>{errors.SingleLine1}</span>
                        </div>
                    </div>
                    <p>{data.title2}</p>
                    <div className='checkbox_item'>
                        <label htmlFor="Checkbox_1"><input type="checkbox" id="Checkbox_1" name="Checkbox" value="Online&#x20;Store" checked={!!values.Checkbox[0]} onChange={onChange}/><span>Online&#x20;Store</span></label>
                        <label htmlFor="Checkbox_2"><input type="checkbox" id="Checkbox_2" name="Checkbox" value="Build&#x20;with&#x20;AI" checked={!!values.Checkbox[1]} onChange={onChange}/><span>Build&#x20;with&#x20;AI</span></label>
                        <label htmlFor="Checkbox_3"><input type="checkbox" id="Checkbox_3" name="Checkbox" value="POS&#x20;&amp;&#x20;OMO" checked={!!values.Checkbox[2]} onChange={onChange}/><span>POS&#x20;&amp;&#x20;OMO</span></label>
                        <label htmlFor="Checkbox_4"><input type="checkbox" id="Checkbox_4" name="Checkbox" value="App&#x20;Builder" checked={!!values.Checkbox[3]} onChange={onChange}/><span>App&#x20;Builder</span></label>
                        <label htmlFor="Checkbox_5"><input type="checkbox" id="Checkbox_5" name="Checkbox" value="Multichannel&#x20;Connect" checked={!!values.Checkbox[4]} onChange={onChange}/><span>Multichannel&#x20;Connect</span></label>
                        <label htmlFor="Checkbox_6"><input type="checkbox" id="Checkbox_6" name="Checkbox" value="Marketing&#x20;Automation&#x20;-&#x20;SmartPush" checked={!!values.Checkbox[5]} onChange={onChange}/><span>Marketing&#x20;Automation&#x20;-&#x20;SmartPush</span></label>
                        <label htmlFor="Checkbox_7"><input type="checkbox" id="Checkbox_7" name="Checkbox" value="Loyalty&#x20;-&#x20;Member&#x20;System" checked={!!values.Checkbox[6]} onChange={onChange}/><span>Loyalty&#x20;-&#x20;Member&#x20;System</span></label>
                        <label htmlFor="Checkbox_8"><input type="checkbox" id="Checkbox_8" name="Checkbox" value="Social&#x20;Commerce&#x20;Solution" checked={!!values.Checkbox[7]} onChange={onChange}/><span>Social&#x20;Commerce&#x20;Solution</span></label>
                        <label htmlFor="Checkbox_9"><input type="checkbox" id="Checkbox_9" name="Checkbox" value="Affiliate&#x20;Marketing" checked={!!values.Checkbox[8]} onChange={onChange}/><span>Affiliate&#x20;Marketing</span></label>
                        <label htmlFor="Checkbox_10"><input type="checkbox" id="Checkbox_10" name="Checkbox" value="Extensible&#x20;Platform" checked={!!values.Checkbox[9]} onChange={onChange}/><span>Extensible&#x20;Platform</span></label>
                        <label htmlFor="Checkbox_11"><input type="checkbox" id="Checkbox_11" name="Checkbox" value="SHOPLINE&#x20;Market" checked={!!values.Checkbox[10]} onChange={onChange}/><span>SHOPLINE&#x20;Market</span></label>
                        <label htmlFor="Checkbox_12"><input type="checkbox" id="Checkbox_12" name="Checkbox" value="SHOPLINE&#x20;B2B" checked={!!values.Checkbox[11]} onChange={onChange}/><span>SHOPLINE&#x20;B2B</span></label>
                    </div>
                    <div>
                        <br/><br/>
                        <Button title='Submit' loading={loading} onTap={onSubmit} />
                        <p className='legal_text'>{parseContent(data.title3)}</p>
                    </div>
               </form> 
            </div>
        </div>
    </div>
  );
}

export default memo(FormLayer);
