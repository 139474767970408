import React, { useEffect, useState, useRef } from 'react';
import { TextSeg, Opa, VideoView, Share, Button } from './components';
import { events, getOffset, HOST, parseContent, STATIC_PATH, scrollTo } from './utils';

let winW = window.innerWidth;
let winH = window.innerHeight;
let groupTops = [0, 0, 0, 0, 0, 0];
let onScrollDelay = null, lockScrollOnce = false, tabActive = 0, tabPicCount = 0;
let scrollAreas = [], lastHashId = "";
let scrollDelayToMenu = null;
 // 加个20px的停顿
let s0sectionInfo = [winW * 0.8, winW * 0.8 * 0.56, 0, 0, 0]; // width, height, top, targetWidth, minTop, maxTop
let s0Info = [winW, winW/1.78, winW * 0.8, winW * 0.8/1.78, 0, 0, 0]; // s0 sction width, height, videoView width, height; top minTop, maxTop

function Content(props) {
  const D = props.data;
  const { Introduction,
    platform_overview,
    online_store,
    integrated_channels,
    meaningful_engagements,
    social_commerce,
    extensibility,
    reaching_new_markets,
    b2b } = D;
  
  const [ onlineTab, setOnlineTab ] = useState({
    active: 0,
    // subActHeights: online_store.store_builder.map((item) => [0,0]),
    subActives: online_store.store_builder.map((item) => 0)
  });
  const [scrollTops, setScrollTops] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [opacitys, setOpacitys] = useState([1, 1, 1, 1, 1, 1, 1, 1]);
  const [v0size, setV0Size] = useState([s0Info[2], s0Info[3]]);
  const contentRef = useRef(null);

  const renderTitle = (title, id) => {
    return <h2>
              <a href="javascript:;" onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(`${HOST}/#c_${id}`);
                  } catch (err) {}
              }}>{title}</a>
          </h2>
  }
  const runLink = (link) => {
    events.emit('openForm');
  }
  const onHashChange = (hash, data) => {
    if (data&&data.from !== 'content') {
      for (let i = 0; i < scrollAreas.length; i++) {
        if ('c_' + scrollAreas[i][2] === hash) {
          let index = ['2_2','2_3','2_4'].indexOf(scrollAreas[i][2]);
          if (index > -1) {
            tabActive = index;
            setOnlineTab({
              ...onlineTab,
              active: index,
            });
          }
          scrollTo(scrollAreas[i][0]-60, 600);
          break;
        }
      }
    }
  }
  const onScroll = (scrollTop) => {
    // console.log(scrollTop);
    if (scrollTop > s0Info[5] && scrollTop < s0Info[6]) {
      let scale = 1 - (s0Info[6] - scrollTop)/(s0Info[6] - s0Info[5]);
      let w = s0Info[2] + scale*(s0Info[0] - s0Info[2]);
      let h = s0Info[3] + scale*(s0Info[1] - s0Info[3]);
      setV0Size([w, h]);
    } else if (scrollTop > s0Info[6]) {
      setV0Size([s0Info[0], s0Info[1]]);
    }
    // if (scrollTop > s0sectionInfo[4] && scrollTop < s0sectionInfo[5]) {
    //   let scale = 1 - (s0sectionInfo[5] - scrollTop)/(s0sectionInfo[5] - s0sectionInfo[4]);
    //   setS0Width(s0sectionInfo[0]+scale*(s0sectionInfo[3] - s0sectionInfo[0]));
    // }
    let lastTop = 0, nowTop = 0;
    if (scrollTop >= 0 && scrollTop < groupTops[0]) {
      setScrollTops([scrollTop, 0, 0, 0, 0, 0, 0, 0]);
      setOpacitys([1, 1, 1, 1, 1, 1, 1, 1]);
    } else if (scrollTop >= groupTops[0] && scrollTop < groupTops[1]) {
      lastTop = groupTops[0];
      nowTop = scrollTop - groupTops[0];
      let scale = Math.max((winH-nowTop)/winH, 0);
      setOpacitys([scale, 1, 1, 1, 1, 1, 1, 1]);
      setScrollTops([lastTop, nowTop, 0, 0, 0, 0, 0, 0]);
    } else if (scrollTop >= groupTops[1] && scrollTop < groupTops[2]) {
      lastTop = groupTops[1] - groupTops[0];
      nowTop = scrollTop - groupTops[0];
      let scale = Math.max((winH-(nowTop-lastTop))/winH, 0);
      setOpacitys([0, scale, 1, 1, 1, 1, 1, 1]);
      setScrollTops([groupTops[0], lastTop, nowTop, 0, 0, 0, 0, 0]);
    } else if (scrollTop >= groupTops[2] && scrollTop < groupTops[3]) {
      lastTop = groupTops[2] - groupTops[0];
      nowTop = scrollTop - groupTops[2] + lastTop;
      let scale = Math.max((winH-(nowTop-lastTop))/winH, 0);
      setOpacitys([0, 0, scale, 1, 1, 1, 1, 1]);
      setScrollTops([groupTops[0], groupTops[1] - groupTops[0], lastTop, nowTop, 0, 0, 0, 0]);
    } else if (scrollTop >= groupTops[3] && scrollTop < groupTops[4]) {
      lastTop = groupTops[3] - groupTops[0];
      nowTop = scrollTop - groupTops[3] + lastTop;
      let scale = Math.max((winH-(nowTop-lastTop))/winH, 0);
      setOpacitys([0, 0, 0, scale, 1, 1, 1, 1]);
      setScrollTops([groupTops[0], groupTops[1] - groupTops[0], groupTops[2] - groupTops[0], lastTop, nowTop, 0, 0, 0]);
    } else if (scrollTop >= groupTops[4] && scrollTop < groupTops[5]) {
      lastTop = groupTops[4] - groupTops[0];
      nowTop = scrollTop - groupTops[4] + lastTop;
      let scale = Math.max((winH-(nowTop-lastTop))/winH, 0);
      setOpacitys([0, 0, 0, 0, scale, 1, 1, 1]);
      setScrollTops([groupTops[0], groupTops[1] - groupTops[0], groupTops[2] - groupTops[0], groupTops[3] - groupTops[0], lastTop, nowTop, 0, 0]);
    } else if (scrollTop >= groupTops[5] && scrollTop < groupTops[6]) {
      lastTop = groupTops[5] - groupTops[0];
      nowTop = scrollTop - groupTops[5] + lastTop;
      let scale = Math.max((winH-(nowTop-lastTop))/winH, 0);
      setOpacitys([0, 0, 0, 0, 0, scale, 1, 1]);
      setScrollTops([groupTops[0], groupTops[1] - groupTops[0], groupTops[2] - groupTops[0], groupTops[3] - groupTops[0], groupTops[4] - groupTops[0], lastTop, nowTop, 0]);
    } else if (scrollTop >= groupTops[6]) {
      lastTop = groupTops[6] - groupTops[0];
      nowTop = scrollTop - groupTops[6] + lastTop;
      let scale = Math.max((winH-(nowTop-lastTop))/winH, 0);
      setOpacitys([0, 0, 0, 0, 0, 0, scale, 1]);
      setScrollTops([groupTops[0], groupTops[1] - groupTops[0], groupTops[2] - groupTops[0], groupTops[3] - groupTops[0], groupTops[4] - groupTops[0], groupTops[5] - groupTops[0], lastTop, nowTop]);
    }
    // 检测滚动到的区域
    if (!lockScrollOnce) {
      let activeIndex = -1;
      // for (let i = scrollAreas.length-1; i >= 0; i--) {
      for (let i = 0; i < scrollAreas.length; i++) {
        if (scrollTop >= scrollAreas[i][0]-scrollAreas[i][1] && scrollTop < scrollAreas[i][0]+scrollAreas[i][1]*2) {
          activeIndex = i;
          break;
        }
      }
      if (activeIndex !== -1) {
        let id = scrollAreas[activeIndex][2];
        if (id !== lastHashId) {
          // if (['2_2','2_3','2_4'].includes(id)) {
          //   id = ['2_2','2_3','2_4'][tabActive];
          // }
          // window.history.pushState({from: 'content'}, '', '#c_' + id);
          clearTimeout(scrollDelayToMenu);
          scrollDelayToMenu = setTimeout(() => {
            events.emit('hash', 'c_' + id, {from: 'content'});
            lastHashId = id;
          }, 100);
        }
      }
    }
    lockScrollOnce = false;
  }
  const onResize = (size) => {
    winW = window.innerWidth;
    winH = window.innerHeight;
    if (size) {
      groupTops = size.slice(2);
    }
    console.log(groupTops);
    let wrapW = document.querySelector('.g1_wrap').offsetWidth;
    s0Info = [wrapW, wrapW/1.78, wrapW * 0.8, wrapW * 0.8/1.78, getOffset(contentRef.current.querySelector('.s0')).top];
    s0sectionInfo = [wrapW * 0.8, wrapW * 0.8 * 0.56, getOffset(contentRef.current.querySelector('.s0')).top];
    // 目标宽度计算：根据window的宽和高来计算使充满整个屏幕
    let targetWidth = wrapW;
    s0Info.push(s0Info[4] - winH + s0Info[3]);
    s0Info.push(s0Info[5] + (s0Info[1] - s0Info[3]));
    s0sectionInfo.push(targetWidth, s0sectionInfo[2] - winH + s0sectionInfo[1], s0sectionInfo[2]);
    clearTimeout(onScrollDelay);
    onScrollDelay = setTimeout(() => {
        // 计算每个部分的高度
        scrollAreas = [];
        for (let key in D) {
          let top = 0;
          if (!D[key].id) {
            for (let key2 in D[key]) {
              let dom = document.querySelector('.c_' + D[key][key2].id);
              if (dom) {
                top = getOffset(dom).top;
                scrollAreas.push([top, dom.offsetHeight*0.5, D[key][key2].id]);
              } 
              // else if (key2 === 'store_builder') {
              //   for (let key3 in D[key][key2]) {
              //     let dom = document.querySelector('.c_' + D[key][key2][key3].id);
              //     if (dom) {
              //       top = getOffset(dom).top;
              //       scrollAreas.push([top, dom.offsetHeight/2, D[key][key2][key3].id]);
              //     }
              //   }
              // }
            }
          } else {
            let dom = document.querySelector('.c_' + D[key].id);
            if (dom) {
              top = getOffset(dom).top;
              scrollAreas.push([top, dom.offsetHeight/2, D[key].id]);
            }
          }
        }
        onScroll(window.scrollY);
    }, 100);
  }
  useEffect(() => {
    tabActive = 0;
    tabPicCount = 0;
    lockScrollOnce = false;
    setScrollTops([0, 0, 0, 0, 0, 0, 0]);
    events.addListener('resize', onResize);
    events.addListener('scroll', onScroll);
    events.addListener('hash', onHashChange);
    return () => {
      events.removeListener('resize', onResize);
      events.removeListener('scroll', onScroll);
      events.removeListener('hash', onHashChange);
    }
  }, []);
  return (
    <div className="content" ref={contentRef}>
      <div className='share_pos'>
        <Share/>
      </div>
      <div className='fixed_scroll_view' style={{transform: `translate3d(0, -${scrollTops[0]}px, 0)`, backgroundImage: `url(${HOST}/sh_line/top_bg.png)`}}>
        <div className='group1'>
          <div className='g1_wrap'>
            <div className={'top_ph c_' + Introduction.id}></div>
            <div className='s0_wrap'>
              <div className='section1 s0' style={{width: v0size[0] + 'px', height: v0size[1] + 'px'}}>
                <div className='sec_video_view'>
                  <div className='sec_v_info sec_padding'>
                    <h3>{Introduction.title2}</h3>
                    <h2 className='sec_v_title'>{Introduction.subtitle2}</h2>
                  </div>
                  <VideoView preViewSrc={`${HOST}/sh_line/pics/Fashion_en.webm`} src=""/>
                </div>
              </div>
            </div>
            <div className={'section1 s1 c_' + platform_overview.id} style={{opacity: opacitys[0]}}>
              <Opa data={platform_overview}/>
              <div className='scroll_tip'>Explore more<br/><br/></div>
            </div>
          </div>
        </div>
        <div className='group2' style={{transform: `translate3d(0, -${scrollTops[1]}px, 0)`}}>
        <div className='group_wrap' style={{opacity: opacitys[1]}}>
          <div className={'section1 s3'}>
            <div className={'sec_video_view c_' + online_store.overview.id}>
              <div className='sec_v_info sec_padding'>
                <h3>{online_store.overview.title}</h3>
                {renderTitle(online_store.overview.subtitle, online_store.overview.id)}
              </div>
              <VideoView preViewSrc={`${STATIC_PATH}${online_store.overview.v1}`} src={online_store.overview.v2}/>
            </div>
            <div className='sec_padding'>
              <div className='sec_s1'>
                <div className='sec_s1_1'>
                  <p>{parseContent(online_store.overview.content)}</p>
                </div>
                <div className='sec_s1_2'>
                  <div className='bg_layer' style={{backgroundImage: `url("${STATIC_PATH}${online_store.overview.pic}.webp")`}}></div>
                </div>
              </div>
              {/* <div className={['sec_tab', ...online_store.store_builder.map(item => `c_${item.id}`)].join(" ")}> */}
              <div className="sec_tab">
                <div className='sec_tab_bar'>
                  {online_store.store_builder.map((item, index) => {
                    if (index === 0) {
                      return [<span key={`3_1_${index}`} onClick={() => {
                        // window.history.pushState({from: 'content'}, '', '#c_' + item.id);
                        tabActive = index;
                        setOnlineTab({
                          ...onlineTab,
                          active: index
                        });
                        // events.emit('hash', 'c_' + item.id, {from: 'content'});
                        setTimeout(onResize, 100);
                        lockScrollOnce = true;
                      }} className={onlineTab.active===0?'active':''}>{item.title}</span>]
                    } else {
                      return [<b key={index + '_0'}></b>, <span onClick={() => {
                        // window.history.pushState({from: 'content'}, '', '#c_' + item.id);
                        tabActive = index;
                        console.log(index);
                        setOnlineTab({
                          ...onlineTab,
                          active: index,
                        });
                        // events.emit('hash', 'c_' + item.id, {from: 'content'});
                        setTimeout(onResize, 100);
                        lockScrollOnce = true;
                      }} className={onlineTab.active===index?'active':''} key={index + '_1'}>{item.title}</span>];
                    }
                  })}
                </div>
                <div className='sec_tab_content'>
                  {online_store.store_builder.map((item, index) => {
                      return <div key={`3_2_${index}`} className={'sec_tab_box ' + (onlineTab.active === index?'show':'')}>
                                <div className='tab_pic'>
                                  <div className='flex_pic_wrap'>
                                  {item.tabs.map((tab, tabIndex) => {
                                    return <div key={`3_5_${index}_${tabIndex}`} className={'bg_layer' + (onlineTab.subActives[index] === tabIndex?' active':'')}
                                            style={{backgroundImage: `url("${STATIC_PATH}${tab.pic}.webp")`}}>
                                          </div>
                                  })}
                                  </div>
                                </div>
                                <div className='tab_main'>
                                  {item.tabs.map((tab, tabIndex) => {
                                    return <div key={`3_2_${index}_${tabIndex}`} onMouseEnter={(ev) => {
                                      if (onlineTab.subActives[index] !== tabIndex) {
                                        let newSubActives = onlineTab.subActives.slice();
                                        // let newSubHeights = onlineTab.subActHeights.slice();
                                        newSubActives[onlineTab.active] = tabIndex;
                                        // console.log(newSubHeights[onlineTab.active], tabIndex);
                                        // if (!newSubHeights[onlineTab.active][tabIndex][0]) {
                                        //   newSubHeights[onlineTab.active][tabIndex] = [
                                        //     ev.currentTarget.offsetTop,
                                        //     ev.currentTarget.offsetHeight
                                        //   ];
                                        // }
                                        setOnlineTab({
                                          active: onlineTab.active,
                                          // subActHeights: onlineTab.subActHeights,
                                          subActives: newSubActives
                                        });
                                      }
                                    }} onClick={() => {
                                      let newSubActives = onlineTab.subActives.slice();
                                      newSubActives[onlineTab.active] = tabIndex;
                                      setOnlineTab({
                                        active: onlineTab.active,
                                        subActives: newSubActives
                                      });
                                    }} className={'tab_texts' + (onlineTab.subActives[index] === tabIndex?' active':'')}>
                                              <div className='tab_pic_every' style={{backgroundImage: `url("${STATIC_PATH}${tab.pic}.webp")`}}></div>
                                              <h4>{tab.title}</h4>
                                              <p>{tab.content}</p>
                                              {tab.btns&&tab.btns.map((btn, btnIndex) => {
                                                return btn.link==="form"?<a key={`${index}_${tabIndex}_${btnIndex}`} href="javascript:;" onClick={() => runLink(btn.link)}>{btn.title}</a>:<a key={`${index}_${tabIndex}_${btnIndex}`} rel="noreferrer" href={btn.link} target='_blank'>{btn.title}</a>
                                              })}
                                            </div>
                                  })}
                                </div>
                              </div>
                  })}
                  
                </div>
                {/* <div className='tab_dots'>
                  {online_store.store_builder.map((item, index) => <span key={`3_3_${index}`} className={onlineTab.active===index?'active':''}></span>)}
                </div> */}
              </div>
            </div>
          </div>
          <div className={'section2 s4 online_store_ai c_' + online_store.ai.id}>
            <div className='s4_1'>
              <div className='flex_pic_wrap'>
                <div className='bg_layer' style={{backgroundImage: `url("${STATIC_PATH}${online_store.ai.pic}.webp")`}}></div>
              </div>
            </div>
            <div className='s4_2'>
              <h3>{online_store.ai.title}</h3>
              {renderTitle(online_store.ai.subtitle, online_store.ai.id)}
              {online_store.ai.content.map((item, index) => {
                if (index === 0) {
                  return <TextSeg key={`4_1_${index}`} title={item.title} content={item.content} btns={item.btns}/>
                }
                return [<div key={`4_2_${index}`} className='s4_line'></div>, <TextSeg key={`4_3_${index}`} title={item.title} content={item.content} btns={item.btns}/>]
              })}
            </div>
          </div>
          <div className={'section2 s5 c_' + online_store.operational_excellence.id}>
            <div className='s4_1'>
              <div className='flex_pic_wrap'>
                <div className='bg_layer' style={{backgroundImage: `url("${STATIC_PATH}${online_store.operational_excellence.pic}.webp")`}}></div>
              </div>
            </div>
            <div className='s4_2'>
              <h3>{online_store.operational_excellence.title}</h3>
              {renderTitle(online_store.operational_excellence.subtitle, online_store.operational_excellence.id)}
              <TextSeg content={online_store.operational_excellence.content} />
            </div>
          </div>
          <div className={'section2 s6 sec_padding c_' + online_store.product_management.id}>
            <h3>{online_store.product_management.title}</h3>
            {renderTitle(online_store.product_management.subtitle, online_store.product_management.id)}
            <div className='sec_wrap'>
              {online_store.product_management.content.map((item, index) => {
                return <div className='sec_item' key={`5_1_${index}`}>
                  <div className='sec_pic' style={{backgroundImage: `url("${STATIC_PATH}${item.pic}.webp")`}}></div>
                  <TextSeg key={`5_1_${index}`} title={item.title} content={item.content} btns={item.btns}/>
                </div>
              })}
            </div>
            <br/><br/>
            {online_store.product_management.content2.map((item, index) => {
                return <div className='sec_text_item' key={`5_2_${index}`}>
                    <h4>{item.title}</h4>
                    <div className='sec_text_flex'>
                      <p>{item.content}</p>
                      <div>{item.btns.map((e, index) => e.link==="form"?<a key={`5_3_${index}`} href="javascript:;" onClick={() => runLink(e.link)}>{e.title}</a>:<a key={`5_3_${index}`} target='_blank' href={e.link}>{e.title}</a>)}</div>
                    </div>
                  </div>
              })}
          </div>
          <div className={'section2 s7 sec_padding c_' + online_store.merchandising.id}>
            <h3>{online_store.merchandising.title}</h3>
            {renderTitle(online_store.merchandising.subtitle, online_store.merchandising.id)}
            <div className='sec_wrap'>
              {online_store.merchandising.content.map((item, index) => {
                return <div className='sec_item' key={`7_1_${index}`}>
                        <div className='sec_pic' style={{backgroundImage: `url("${STATIC_PATH}${item.pic}.webp")`}}></div>
                        <TextSeg key={`7_1_${index}`} title={item.title} content={item.content} btns={item.btns}/>
                      </div>
              })}
              
            </div>
            <br/><br/>
            {online_store.merchandising.content2.map((item, index) => {
              return <div className='sec_text_item' key={`7_2_${index}`}>
                  <h4>{item.title}</h4>
                  <div className='sec_text_flex'>
                    <p>{item.content}</p>
                    <div>{item.btns.map((e, index) => e.link==="form"?<a key={`7_3_${index}`} href="javascript:;" onClick={() => runLink(e.link)}>{e.title}</a>:<a key={`7_3_${index}`} target='_blank' href={e.link}>{e.title}</a>)}</div>
                  </div>
                </div>
            })}
          </div>
          <div className={'section2 s8 sec_padding c_' + online_store.promotion_engine.id}>
            <h3>{online_store.promotion_engine.title}</h3>
            {renderTitle(online_store.promotion_engine.subtitle, online_store.promotion_engine.id)}
            <div className='sec_wrap'>
              {online_store.promotion_engine.content.map((item, index) => {
                return <div className='sec_item' key={`8_1_${index}`}>
                        <div className='sec_pic' style={{backgroundImage: `url("${STATIC_PATH}${item.pic}.webp")`}}></div>
                        <TextSeg key={`8_1_${index}`} title={item.title} content={item.content} btns={item.btns}/>
                      </div>
              })}
            </div>
          </div>
          <div className={'section2 s9 sec_padding c_' + online_store.inventory_management.id}>
            <h3>{online_store.inventory_management.title}</h3>
            {renderTitle(online_store.inventory_management.subtitle, online_store.inventory_management.id)}
            <div className='sec_wrap'>
              {online_store.inventory_management.content.map((item, index) => {
                return <div className='sec_item' key={`9_1_${index}`}>
                        <div className='sec_pic' style={{backgroundImage: `url("${STATIC_PATH}${item.pic}.webp")`}}></div>
                        <TextSeg key={`9_1_${index}`} title={item.title} content={item.content} btns={item.btns}/>
                      </div>
              })}
            </div>
          </div>
          <div className={'section2 s10 sec_padding c_' + online_store.order_management.id}>
            <h3>{online_store.order_management.title}</h3>
            {renderTitle(online_store.order_management.subtitle, online_store.order_management.id)}
            <div className='sec_wrap'>
              {online_store.order_management.content.map((item, index) => {
                return <div className='sec_item' key={`10_1_${index}`}>
                        <div className='sec_pic' style={{backgroundImage: `url("${STATIC_PATH}${item.pic}.webp")`}}></div>
                        <TextSeg key={`10_1_${index}`} title={item.title} content={item.content} btns={item.btns}/>
                      </div>
              })}
            </div>
            <br/><br/>
            {online_store.order_management.content2.map((item, index) => {
              return <div className='sec_text_item' key={`10_2_${index}`}>
                  <h4>{item.title}</h4>
                  <div className='sec_text_flex'>
                    <p>{item.content}</p>
                    <div>{item.btns.map((e, index) => e.link==="form"?<a key={index} href="javascript:;" onClick={() => runLink(e.link)}>{e.title}</a>:<a key={index} target='_blank' href={e.link}>{e.title}</a>)}</div>
                  </div>
                </div>
            })}
          </div>
          <div className={'section2 s11 sec_padding c_' + online_store.checkout_payment.id}>
            <h3>{online_store.checkout_payment.title}</h3>
            {renderTitle(online_store.checkout_payment.subtitle, online_store.checkout_payment.id)}
            <TextSeg title={online_store.checkout_payment.content.title} content={online_store.checkout_payment.content.content}/>
            <div className='s11_nums'>
              {online_store.checkout_payment.content2.map((item, index) => {
                return <div className='s11_nums_item' key={`11_1_${index}`}>
                        <strong className='wel_text'>{item.number}%</strong>
                        <span>{item.title}</span>
                      </div>
              })}
            </div>
            <div className='sec_wrap sec_v'>
              {online_store.checkout_payment.content3.map((item, index) => {
                return <div className='sec_item' key={`11_2_${index}`}>
                        <div className='sec_pic' style={{backgroundImage: `url("${STATIC_PATH}${item.pic}.webp")`}}></div>
                        <TextSeg key={`11_1_${index}`} title={item.title} content={item.content} btns={item.btns}/>
                      </div>
              })}
            </div>
            <br/><br/>
            {online_store.checkout_payment.content4.map((item, index) => {
              return <div className='sec_text_item' key={`11_3_${index}`}>
                  <h4>{item.title}</h4>
                  <div className='sec_text_flex'>
                    <p>{item.content}</p>
                    <div>{item.btns.map((e, index) => e.link==="form"?<a key={`11_4_${index}`} href="javascript:;" onClick={() => runLink(e.link)}>{e.title}</a>:<a key={`11_4_${index}`} target='_blank' href={e.link}>{e.title}</a>)}</div>
                  </div>
                </div>
            })}
          </div>
          <div className={'section2 s12 sec_padding c_' + online_store.analytics.id}>
          <h3>{online_store.analytics.title}</h3>
          {renderTitle(online_store.analytics.subtitle, online_store.analytics.id)}
            <div className='sec_wrap'>
              {online_store.analytics.content.map((item, index) => {
                return <div className='sec_item' key={`12_1_${index}`}>
                        <div className='sec_pic' style={{backgroundImage: `url("${STATIC_PATH}${item.pic}.webp")`}}></div>
                        <TextSeg key={`12_1_${index}`} title={item.title} content={item.content} btns={item.btns}/>
                      </div>
              })}
            </div>
          </div>
          <div className={'section2 s13 v1_wrap c_' + online_store.success_story.id}>
            <div className='v1_item'>
              <div className='v1_video_wrap' style={{backgroundImage: `url("${STATIC_PATH}${online_store.success_story.pic}.webp")`}}>
                  <img src={`${STATIC_PATH}${online_store.success_story.pic2}.webp`} width="90%" alt="Christopher Yang" />
              </div>
            </div>
            <div className='v1_item sec_padding'>
              <h3>{online_store.success_story.title}</h3>
              {renderTitle(online_store.success_story.subtitle, online_store.success_story.id)}
              <TextSeg content={online_store.success_story.content} btns={online_store.success_story.btns}/>
            </div>
          </div>
          </div>
        </div>

        <div className='group3' style={{transform: `translate3d(0, -${scrollTops[2]}px, 0)`, opacity: opacitys[2]}}>
        <div className='group_wrap' style={{opacity: opacitys[2]}}>
          <div className={'section1 s14 c_' + integrated_channels.overview.id}>
            <div className='sec_video_view'>
              <div className='sec_v_info sec_padding'>
                <h3>{integrated_channels.overview.title}</h3>
                {renderTitle(integrated_channels.overview.subtitle, integrated_channels.overview.id)}
              </div>
              <div className='sec_image' style={{backgroundImage: `url("${STATIC_PATH}${integrated_channels.overview.pic}.webp")`}}></div>
            </div>
            <div className='sec_padding'>
              <p>{parseContent(integrated_channels.overview.content)}</p>
            </div>
          </div>
          <div className={'section2 s15 c_' + integrated_channels.retail_pos.id}>
            <div className='video_padding'>
              <div className='sec_video_view'>
                <div className='sec_v_info sec_padding'>
                  <h3>{integrated_channels.retail_pos.title}</h3>
                  {renderTitle(integrated_channels.retail_pos.subtitle, integrated_channels.retail_pos.id)}
                </div>
                <VideoView padding preViewSrc={`${STATIC_PATH}${integrated_channels.retail_pos.v1}`} src={integrated_channels.retail_pos.v2}/>
              </div>
            </div>
            
            <div className='sec_padding'>
              <div className='sec_wrap'>
                {integrated_channels.retail_pos.content.map((item, index) => {
                  return <div className='sec_item' key={`15_1_${index}`}>
                          <div className='sec_pic' style={{backgroundImage: `url("${STATIC_PATH}${item.pic}.webp")`}}></div>
                          <TextSeg key={`15_1_${index}`} title={item.title} content={item.content} btns={item.btns}/>
                        </div>
                })}
              </div>
              <br/><br/>
              {integrated_channels.retail_pos.content2.map((item, index) => {
                return <div className='sec_text_item' key={`15_2_${index}`}>
                    <h4>{item.title}</h4>
                    <div className='sec_text_flex'>
                      <p>{item.content}</p>
                      <div>{item.btns.map((e, index) => e.link==="form"?<a key={`15_3_${index}`} href="javascript:;" onClick={() => runLink(e.link)}>{e.title}</a>:<a key={`15_3_${index}`} target='_blank' href={e.link}>{e.title}</a>)}</div>
                    </div>
                  </div>
              })}
            </div>
          </div>
          <div className={'section2 s16 v1_wrap c_' + integrated_channels.success_story.id}>
            <div className='v1_item'>
              <div className='v1_video_wrap' style={{backgroundImage: `url("${STATIC_PATH}${integrated_channels.success_story.pic}.webp")`}}>
                <div className='v1_video_box'>
                  <VideoView preViewSrc={`${STATIC_PATH}${integrated_channels.success_story.v1}`} src={integrated_channels.success_story.v2}/>
                </div>
              </div>
            </div>
            <div className='v1_item sec_padding'>
              <h3>{integrated_channels.success_story.title}</h3>
              {renderTitle(integrated_channels.success_story.subtitle, integrated_channels.success_story.id)}
              <TextSeg content={integrated_channels.success_story.content} btns={integrated_channels.success_story.btns}/>
            </div>
          </div>
          <div className={'section2 s17 sec_padding c_' + integrated_channels.mobile_experience.id}>
            <div className='sec_wrap sec_v'>
              <div className='sec_item'>
                <div className='sec_pic' style={{backgroundImage: `url("${STATIC_PATH}${integrated_channels.mobile_experience.content.pic}.webp")`}}></div>
                <div className='seg_text'>
                  <h3>{integrated_channels.mobile_experience.title}</h3>
                  {renderTitle(integrated_channels.mobile_experience.subtitle, integrated_channels.mobile_experience.id)}
                  <p>{parseContent(integrated_channels.mobile_experience.content.content)}</p>
                </div>
              </div>
              {integrated_channels.mobile_experience.content2.map((item, index) => {
                return <div className='sec_item' key={`17_1_${index}`}>
                        <div className='sec_pic' style={{backgroundImage: `url("${STATIC_PATH}${item.pic}.webp")`}}></div>
                        <TextSeg key={`17_1_${index}`} title={item.title} content={item.content} btns={item.btns}/>
                      </div>
              })}
            </div>
          </div>
          <div className={'section2 s26 c_' + integrated_channels.multichannel_connect.id}>
            <div className='s4_wrap'>
                <div className='s4_1'>
                  <div className='flex_pic_wrap'>
                    <div className='bg_layer' style={{backgroundImage: `url("${STATIC_PATH}${integrated_channels.multichannel_connect.content.pic}.webp")`}}></div>
                  </div>
                </div>
                <div className='s4_2'>
                  <h3>{integrated_channels.multichannel_connect.title}</h3>
                  {renderTitle(integrated_channels.multichannel_connect.subtitle, integrated_channels.multichannel_connect.id)}
                  <TextSeg content={integrated_channels.multichannel_connect.content.content} />
                </div>
            </div>
            <div className='sec_padding'>
              {integrated_channels.multichannel_connect.content2.map((item, index) => {
                return <div className='sec_text_item' key={index}>
                    <h4>{item.title}</h4>
                    <div className='sec_text_flex'>
                      <p>{item.content}</p>
                      <div>{item.btns.map((e, index) => e.link==="form"?<a key={`25_3_${index}`} href="javascript:;" onClick={() => runLink(e.link)}>{e.title}</a>:<a key={`25_3_${index}`} target='_blank' href={e.link}>{e.title}</a>)}</div>
                    </div>
                  </div>
              })}
            </div>
          </div>
          
          </div>
        </div>
        <div className='group4' style={{transform: `translate3d(0, -${scrollTops[3]}px, 0)`}}>
          <div className='group_wrap' style={{opacity: opacitys[3]}}>
          <div className={'section1 s18 c_' + meaningful_engagements.overview.id}>
            <div className='sec_video_view'>
              <div className='sec_v_info sec_padding'>
                <h3>{meaningful_engagements.overview.title}</h3>
                {renderTitle(meaningful_engagements.overview.subtitle, meaningful_engagements.overview.id)}
              </div>
              <div className='sec_image' style={{backgroundImage: `url("${STATIC_PATH}${meaningful_engagements.overview.pic}.webp")`}}></div>
            </div>
            <div className='sec_padding'>
              <p>{parseContent(meaningful_engagements.overview.content)}</p>
            </div>
          </div>
          <div className={'section2 s19 c_' + meaningful_engagements.smart_push.id}>
            <div className='video_padding'>
              <div className='sec_video_view'>
                <div className='sec_v_info sec_padding'>
                  <h3>{meaningful_engagements.smart_push.title}</h3>
                  {renderTitle(meaningful_engagements.smart_push.subtitle, meaningful_engagements.smart_push.id)}
                </div>
                <VideoView preViewSrc={`${STATIC_PATH}${meaningful_engagements.smart_push.v1}`} src={meaningful_engagements.smart_push.v2}/>
                {/* <div className='sec_image' style={{backgroundImage: `url("${STATIC_PATH}${meaningful_engagements.smart_push.pic}.webp")`}}></div> */}
              </div>
            </div>
            <div className='sec_padding'>
              <div className='sec_wrap sec_v'>
                {meaningful_engagements.smart_push.content.map((item, index) => {
                  return <div className='sec_item' key={`19_1_${index}`}>
                          <div className='sec_pic' style={{backgroundImage: `url("${STATIC_PATH}${item.pic}.webp")`}}></div>
                          <TextSeg key={`19_1_${index}`} title={item.title} content={item.content} btns={item.btns}/>
                        </div>
                })}
              </div>
            </div>
          </div>

          <div className={'section2 s20 c_' + meaningful_engagements.member_system.id}>
            <div className='video_padding'>
              <div className='sec_video_view'>
                <div className='sec_v_info sec_padding'>
                  <h3>{meaningful_engagements.member_system.title}</h3>
                  {renderTitle(meaningful_engagements.member_system.subtitle, meaningful_engagements.member_system.id)}
                </div>
                <VideoView preViewSrc={`${STATIC_PATH}${meaningful_engagements.member_system.v1}`} src={meaningful_engagements.member_system.v2}/>
              </div>
            </div>
            <div className='sec_padding'>
              <div className='sec_wrap sec_v'>
                {meaningful_engagements.member_system.content.map((item, index) => {
                  return <div className='sec_item' key={`20_1_${index}`}>
                          <div className='sec_pic' style={{backgroundImage: `url("${STATIC_PATH}${item.pic}.webp")`}}></div>
                          <TextSeg key={`20_1_${index}`} title={item.title} content={item.content} btns={item.btns}/>
                        </div>
                })}
              </div>
              <br/><br/>
              {meaningful_engagements.member_system.content2.map((item, index) => {
                return <div className='sec_text_item' key={index}>
                    <h4>{item.title}</h4>
                    <div className='sec_text_flex'>
                      <p>{item.content}</p>
                      <div>{item.btns.map((e, index) => e.link==="form"?<a key={`20_2_${index}`} href="javascript:;" onClick={() => runLink(e.link)}>{e.title}</a>:<a key={`20_2_${index}`} target='_blank' href={e.link}>{e.title}</a>)}</div>
                    </div>
                  </div>
              })}
            </div>
          </div>
          <div className={'section2 s21 sec_padding c_' + meaningful_engagements.actionale_insights.id}>
            <h3>{meaningful_engagements.actionale_insights.title}</h3>
            {renderTitle(meaningful_engagements.actionale_insights.subtitle, meaningful_engagements.actionale_insights.id)}
            <div className='sec_wrap'>
              {meaningful_engagements.actionale_insights.content.map((item, index) => {
                return <div className='sec_item' key={`21_1_${index}`}>
                        <div className='sec_pic' style={{backgroundImage: `url("${STATIC_PATH}${item.pic}.webp")`}}></div>
                        <TextSeg key={`21_1_${index}`} title={item.title} content={item.content} btns={item.btns}/>
                      </div>
              })}
            </div>
          </div>
          </div>
        </div>
        <div className='group5' style={{transform: `translate3d(0, -${scrollTops[4]}px, 0)`}}>
          <div className='group_wrap' style={{opacity: opacitys[4]}}>
          <div className={'section1 s22 c_' + social_commerce.overview.id}>
            <div className='sec_video_view'>
              <div className='sec_v_info sec_padding'>
                <h3>{social_commerce.overview.title}</h3>
                {renderTitle(social_commerce.overview.subtitle, social_commerce.overview.id)}
              </div>
              <div className='sec_image' style={{backgroundImage: `url("${STATIC_PATH}${social_commerce.overview.pic}.webp")`}}></div>
            </div>
            <div className='sec_padding'>
              <TextSeg content={social_commerce.overview.content} btns={social_commerce.overview.btns}/>
            </div>
          </div>
          <div className={'section2 s23 sec_padding c_' + social_commerce.social_ads.id}>
            <h3>{social_commerce.social_ads.title}</h3>
            {renderTitle(social_commerce.social_ads.subtitle, social_commerce.social_ads.id)}
            <div className='sec_wrap'>
              {social_commerce.social_ads.content.map((item, index) => {
                return <div className='sec_item' key={`23_1_${index}`}>
                        <div className='sec_pic' style={{backgroundImage: `url("${STATIC_PATH}${item.pic}.webp")`}}></div>
                        <TextSeg key={`23_1_${index}`} title={item.title} content={item.content} btns={item.btns}/>
                      </div>
              })}
            </div>
          </div>
          <div className={'section2 s24 sec_padding c_' + social_commerce.affiliate_marketing.id}>
            <div className='sec_wrap sec_v'>
              <div className='sec_item'>
                <div className='sec_pic' style={{backgroundImage: `url("${STATIC_PATH}${social_commerce.affiliate_marketing.content.pic}.webp")`}}></div>
                <div className='seg_text'>
                  <h3>{social_commerce.affiliate_marketing.title}</h3>
                  {renderTitle(social_commerce.affiliate_marketing.subtitle, social_commerce.affiliate_marketing.id)}
                  <p>{parseContent(social_commerce.affiliate_marketing.content.content)}</p>
                  {(social_commerce.affiliate_marketing.content.btns||[]).map((btn, index) => {
                      return <Button key={`24_0_${index}`} {...btn}/>
                  })}
                </div>
              </div>
              {social_commerce.affiliate_marketing.content2.map((item, index) => {
                return <div className='sec_item' key={`24_2_${index}`}>
                        <div className='sec_pic' style={{backgroundImage: `url("${STATIC_PATH}${item.pic}.webp")`}}></div>
                        <TextSeg key={`24_1_${index}`} title={item.title} content={item.content} btns={item.btns}/>
                      </div>
              })}
            </div>
            <br/><br/>
            {social_commerce.affiliate_marketing.content3.map((item, index) => {
              return <div className='sec_text_item' key={index}>
                  <h4>{item.title}</h4>
                  <div className='sec_text_flex'>
                    <p>{item.content}</p>
                    <div>{item.btns.map((e, index) => e.link==="form"?<a key={`24_3_${index}`} href="javascript:;" onClick={() => runLink(e.link)}>{e.title}</a>:<a key={`24_3_${index}`} target='_blank' href={e.link}>{e.title}</a>)}</div>
                  </div>
                </div>
            })}
          </div>
          <div className={'section2 s25 c_' + social_commerce.tiktok_shop.id}>
            <div className='s4_wrap'>
                <div className='s4_1'>
                  <div className='flex_pic_wrap'>
                    <div className='bg_layer' style={{backgroundImage: `url("${STATIC_PATH}${social_commerce.tiktok_shop.content.pic}.webp")`}}></div>
                  </div>
                </div>
                <div className='s4_2'>
                  <h3>{social_commerce.tiktok_shop.title}</h3>
                  {renderTitle(social_commerce.tiktok_shop.subtitle, social_commerce.tiktok_shop.id)}
                  <TextSeg content={social_commerce.tiktok_shop.content.content} btns={social_commerce.tiktok_shop.content.btns} />
                </div>
            </div>
            <div className='sec_padding'>
              {social_commerce.tiktok_shop.content2.map((item, index) => {
                return <div className='sec_text_item' key={index}>
                    <h4>{item.title}</h4>
                    <div className='sec_text_flex'>
                      <p>{item.content}</p>
                      <div>{item.btns.map((e, index) => e.link==="form"?<a key={`25_3_${index}`} href="javascript:;" onClick={() => runLink(e.link)}>{e.title}</a>:<a key={`25_3_${index}`} target='_blank' href={e.link}>{e.title}</a>)}</div>
                    </div>
                  </div>
              })}
            </div>
          </div>
          <div className={'section2 s26 c_' + social_commerce.live_selling.id}>
            <div className='video_padding'>
              <div className='sec_video_view'>
                <div className='sec_v_info sec_padding'>
                  <h3>{social_commerce.live_selling.title}</h3>
                  {renderTitle(social_commerce.live_selling.subtitle, social_commerce.live_selling.id)}
                </div>
                <VideoView preViewSrc={`${STATIC_PATH}${social_commerce.live_selling.v1}`} src={meaningful_engagements.member_system.v2}/>
                <p className='sec_padding'>{parseContent(social_commerce.live_selling.content)}</p>
              </div>
            </div>
            <div className='sec_padding'>
              <div className='sec_wrap sec_v'>
                {social_commerce.live_selling.content2.map((item, index) => {
                  return <div className='sec_item' key={`25_2_${index}`}>
                          <div className='sec_pic' style={{backgroundImage: `url("${STATIC_PATH}${item.pic}.webp")`}}></div>
                          <TextSeg key={`25_1_${index}`} title={item.title} content={item.content} btns={item.btns}/>
                        </div>
                })}
              </div>
              <br/><br/>
            {social_commerce.live_selling.content3.map((item, index) => {
              return <div className='sec_text_item' key={index}>
                  <h4>{item.title}</h4>
                  <div className='sec_text_flex'>
                    <p>{item.content}</p>
                    <div>{item.btns.map((e, index) => e.link==="form"?<a key={`25_3_${index}`} href="javascript:;" onClick={() => runLink(e.link)}>{e.title}</a>:<a key={`25_3_${index}`} target='_blank' href={e.link}>{e.title}</a>)}</div>
                  </div>
                </div>
            })}
            </div>
          </div>
          
          <div className={'section2 s27 sec_padding c_' + social_commerce.chat_commerce.id}>
            <div className='sec_wrap sec_v'>
                <div className='sec_item'>
                  <div className='sec_pic' style={{backgroundImage: `url("${STATIC_PATH}${social_commerce.chat_commerce.content.pic}.webp")`}}></div>
                  <div className='seg_text'>
                    <h3>{social_commerce.chat_commerce.title}</h3>
                    {renderTitle(social_commerce.chat_commerce.subtitle, social_commerce.chat_commerce.id)}
                    <p>{parseContent(social_commerce.chat_commerce.content.content)}</p>
                  </div>
                </div>
                {social_commerce.chat_commerce.content2.map((item, index) => {
                  return <div className='sec_item' key={`24_2_${index}`}>
                          <div className='sec_pic' style={{backgroundImage: `url("${STATIC_PATH}${item.pic}.webp")`}}></div>
                          <TextSeg key={`24_2_${index}`} title={item.title} content={item.content} btns={item.btns}/>
                        </div>
                })}
              </div>
          </div>
          <div className={'section2 s28 v1_wrap c_' + social_commerce.success_story.id}>
            <div className='v1_item'>
              <div className='v1_video_wrap' style={{backgroundImage: `url("${STATIC_PATH}${social_commerce.success_story.pic}.webp")`}}>
                <div className='v1_video_box'>
                  <VideoView preViewSrc={`${STATIC_PATH}${social_commerce.success_story.v1}`} src={social_commerce.success_story.v2}/>
                </div>
              </div>
            </div>
            <div className='v1_item sec_padding'>
              <h3>{social_commerce.success_story.title}</h3>
              {renderTitle(social_commerce.success_story.subtitle, social_commerce.success_story.id)}
              <TextSeg content={social_commerce.success_story.content} btns={social_commerce.success_story.btns}/>
            </div>
          </div>
          </div>
        </div>
        <div className='group6' style={{transform: `translate3d(0, -${scrollTops[5]}px, 0)`}}>
          <div className='group_wrap' style={{opacity: opacitys[5]}}>
          <div className={'section2 s29 c_' + extensibility.dev_tools.id}>
            <div className='sec_video_view'>
              <div className='sec_v_info sec_padding'>
                <h3>{extensibility.dev_tools.title}</h3>
                {renderTitle(extensibility.dev_tools.subtitle, extensibility.dev_tools.id)}
              </div>
              <VideoView preViewSrc={`${STATIC_PATH}${extensibility.dev_tools.v1}`} src={extensibility.dev_tools.v2}/>
            </div>
            <div className='sec_padding'>
              <div className='sec_wrap'>
                {extensibility.dev_tools.content.map((item, index) => {
                  return <div className='sec_item' key={`29_2_${index}`}>
                          <TextSeg key={`29_1_${index}`} title={item.title} content={item.content} btns={item.btns}/>
                        </div>
                })}
              </div>
              <br/><br/>
              <h2>{extensibility.dev_tools.subtitle2}</h2>
            {extensibility.dev_tools.content2.map((item, index) => {
              return <div className='sec_text_item' key={index}>
                  <h4>{item.title}</h4>
                  <div className='sec_text_flex'>
                    <p>{item.content}</p>
                    <div>{item.btns.map((e, index) => e.link==="form"?<a key={`29_3_${index}`} href="javascript:;" onClick={() => runLink(e.link)}>{e.title}</a>:<a key={`29_3_${index}`} target='_blank' href={e.link}>{e.title}</a>)}</div>
                  </div>
                </div>
            })}
            </div>
          </div>
          <div className={'section2 s30 c_' + extensibility.integrations.id}>
            <div className='s4_wrap'>
                <div className='s4_1'>
                  <div className='flex_pic_wrap'>
                    <div className='bg_layer' style={{backgroundImage: `url("${STATIC_PATH}${extensibility.integrations.content.pic}.webp")`}}></div>
                  </div>
                </div>
                <div className='s4_2'>
                  <h3>{extensibility.integrations.title}</h3>
                  {renderTitle(extensibility.integrations.subtitle, extensibility.integrations.id)}
                  <TextSeg content={extensibility.integrations.content.content} btns={extensibility.integrations.content.btns}/>
                </div>
            </div>
            <div className='sec_padding'>
              <div className='sec_wrap'>
                {extensibility.integrations.content2.map((item, index) => {
                  return <div className='sec_item' key={`30_2_${index}`}>
                          <div className='sec_pic' style={{backgroundImage: `url("${STATIC_PATH}${item.pic}.webp")`}}></div>
                          <TextSeg key={`30_1_${index}`} title={item.title} content={item.content} btns={item.btns}/>
                        </div>
                })}
              </div>
            </div>
          </div>
          </div>
        </div>
        <div className='group7' style={{transform: `translate3d(0, -${scrollTops[6]}px, 0)`}}>
          <div className='group_wrap' style={{opacity: opacitys[6]}}>
          <div className={'section1 s31 c_' + reaching_new_markets.id}>
            <div className='sec_video_view'>
              <div className='sec_v_info sec_padding'>
                <h3>{reaching_new_markets.title}</h3>
                {renderTitle(reaching_new_markets.subtitle, reaching_new_markets.id)}
              </div>
              <div className='sec_image' style={{backgroundImage: `url("${STATIC_PATH}${reaching_new_markets.content.pic}.webp")`}}></div>
            </div>
            <div className='sec_padding'><TextSeg content={reaching_new_markets.content.content} btns={reaching_new_markets.content.btns}/></div>
            <div className='sec_padding'>
              <div className='sec_wrap sec_v'>
                {reaching_new_markets.content2.map((item, index) => {
                  return <div className='sec_item' key={`31_2_${index}`}>
                          <div className='sec_pic' style={{backgroundImage: `url("${STATIC_PATH}${item.pic}.webp")`}}></div>
                          <TextSeg key={`31_1_${index}`} title={item.title} content={item.content} btns={item.btns}/>
                        </div>
                })}
              </div>
            </div>
          </div>
          </div>
        </div>
        <div className='group8' style={{transform: `translate3d(0, -${scrollTops[7]}px, 0)`}}>
          <div className='group_wrap' style={{opacity: opacitys[7]}}>
          <div className={'section1 s32 c_' + b2b.overview.id}>
            <div className='sec_video_view'>
              <div className='sec_v_info sec_padding'>
                <h3>{b2b.overview.title}</h3>
                {renderTitle(b2b.overview.subtitle, b2b.overview.id)}
              </div>
              <div className='sec_image' style={{backgroundImage: `url("${STATIC_PATH}${b2b.overview.content.pic}.webp")`}}></div>
            </div>
            <div className='sec_padding'><TextSeg content={b2b.overview.content.content} btns={b2b.overview.content.btns}/></div>
            <div className='sec_padding'>
              <div className='sec_wrap sec_v'>
                {b2b.overview.content2.map((item, index) => {
                  return <div className='sec_item' key={`31_2_${index}`}>
                          <div className='sec_pic' style={{backgroundImage: `url("${STATIC_PATH}${item.pic}.webp")`}}></div>
                          <TextSeg key={`31_1_${index}`} title={item.title} content={item.content} btns={item.btns}/>
                        </div>
                })}
              </div>
            </div>
          </div>
          <div className={'section2 s33 v1_wrap c_' + b2b.merchant_interview__kayser_roth.id}>
            <div className='v1_item'>
              <div className='v1_video_wrap' style={{backgroundImage: `url("${STATIC_PATH}${b2b.merchant_interview__kayser_roth.pic}.webp")`}}>
                <div className='v1_video_box'>
                  <VideoView preViewSrc={`${STATIC_PATH}${b2b.merchant_interview__kayser_roth.v1}`} src={b2b.merchant_interview__kayser_roth.v2}/>
                </div>
              </div>
            </div>
            <div className='v1_item sec_padding'>
              <h3>{b2b.merchant_interview__kayser_roth.title}</h3>
              {renderTitle(b2b.merchant_interview__kayser_roth.subtitle, b2b.merchant_interview__kayser_roth.id)}
              <TextSeg content={b2b.merchant_interview__kayser_roth.content} btns={b2b.merchant_interview__kayser_roth.btns}/>
            </div>
          </div>
          <div className='footer'>
            <div className='concat'>
              <h3>Expand your business horizons with SHOPLINE</h3>
              <Button title="Get in touch" onTap={() => {
                events.emit('openForm');
              }}/>
            </div>
            <div className='powerby'>
              <div>@ SHOPLINE Inc</div>
              <div><a href="https://www.shopline.com/terms" target='_blank'>Terms of service</a><a href="https://www.shopline.com/privacy" target='_blank'>Privacy policy</a></div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Content;
