const site_data = {
    "menus": [
        {
            "title": "Introduction",
            "subMenus": [],
            "id": "0"
        },
        {
            "title": "Platform Overview",
            "subMenus": [],
            "id": "1"
        },
        {
            "title": "Online Store",
            "subMenus": [
                ["Overview", "2_1"],
                ["Build with AI", "2_5"],
                ["Operational Excellence", "2_6"],
                ["Product Management", "2_7"],
                ["Merchandising Tools", "2_8"],
                ["Promotion Engine", "2_9"],
                ["Inventory Management", "2_10"],
                ["Order & Fulfillment", "2_11"],
                ["Checkout & Payment", "2_12"],
                ["Analytics", "2_13"],
                ["Success Story: Bricks World", "2_14"]
            ]
        },
        {
            "title": "Integrated Channels",
            "subMenus": [
                ["Overview", "3_1"],
                ["Retail POS", "3_2"],
                ["Success Story: Sunnystep", "3_3"],
                ["Mobile Experience", "3_4"],
                ["Multichannel Connect", "3_5"]
            ]
        },
        {
            "title": "Meaningful Engagements",
            "subMenus": [
                ["Overview", "4_1"],
                ["Marketing Automation - SmartPush", "4_2"],
                ["Loyalty - Member System", "4_3"],
                ["Actionable Insights", "4_4"]
            ]
        },
        {
            "title": "Social Commerce",
            "subMenus": [
                ["Overview", "5_1"],
                ["Social Ads", "5_2"],
                ["Affiliate Marketing", "5_3"],
                ["TikTok Integration", "5_4"],
                ["Live Selling", "5_5"],
                ["Chat Commerce", "5_6"],
                ["Success Story: In The Style", "5_7"]
            ]
        },
        {
            "title": "Extensibility",
            "subMenus": [
                ["Dev Tools and APIs", "6_1"],
                ["App and Integrations", "6_2"]
            ]
        },
        {
            "title": "Global Selling",
            "subMenus": [],
            "id": "7"
        },
        {
            "title": "B2B & Wholesale",
            "subMenus": [
                ["Overview", "8_1"],
                ["Merchant Spotlight: Kayser-Roth", "8_2"]
            ]
        }
    ],
    "content": {
        "Introduction": {
            "title": "Welcome to SHOPLINE Horizons 2024",
            "subtitle": "Expanding the horizons of commerce with our latest releases — making it easier, faster, and smarter to build and scale your business.",
            "title2": "Expanding the horizons of commerce",
            "subtitle2": "A word from our co-president, Christopher Yang",
            "id": "0"
        },
        "platform_overview": {
            "title": "Expanding the horizons of commerce",
            "subtitle": "SHOPLINE Product Architecture",
            "id": "1",
            "opa": {
                "core": {
                    "inside": ["Product", "Customer", "Discount", "Checkout", "Order", "Analytics"],
                    "outside": {
                        "l": ["Personalized Engagement", "Customer Intelligence", "Membership", "SmartPush"],
                        "r": ["OMNICHANNEL", "Online Store", "Point of Sale", "Shopper App", "Socials", "Marketplace"]
                    }
                },
                "api": ["Payment", "Business Intelligence", "Customer Service", "Logistics", "Fulfillment", "Store Design", "Social Media", "Marketing", "Merchandising", "Advanced Search"]
            }
        },
        "online_store": {
            "overview": {
                "title": "Online Store",
                "subtitle": "Build, Launch and Scale your Business with Ease",
                "v1": "Fashion_en.webm",
                "v2": "https://www.youtube.com/watch?v=5A01MmF9oGY&list=PLq_mgGsNAhDg6N7FeKdcvTGt_WLsEYuAV&index=13",
                "content": "A simpler and faster way to bring your vision to life. SHOPLINE’s Online Store enables you to design and launch a fully operational store with ease, while enabling you to dynamically streamline, optimize and personalize every stage of the shopping experience with an integrated suite of intelligence-driven components.",
                "pic": "2_Online_store_content_image",
                "id": "2_1" 
            },
            "store_builder": [
                {
                    "title": "Store Builder",
                    "tabs": [
                        {
                            "title": "Theme 3.0",
                            "content": "Offers an intuitive editing experience and rapid load times, fully optimized for mobile.",
                            "pic": "3_Online_store_content_image",
                            "btns": [
                                {
                                    "title": "Get in touch",
                                    "link": "form"
                                }
                            ]
                        },
                        {
                            "title": "Theme editor",
                            "content": "Design and create your store with ease, with a drag and drop interface, complete with a robust set of editing tools.",
                            "pic": "4_Online_store_content_image",
                            "btns": [
                                {
                                    "title": "Learn more",
                                    "link": "https://help.shopline.com/hc/en-001/articles/900004297866-Designing-Your-Store-with-the-Theme-Editor"
                                }
                            ]
                        },
                        {
                            "title": "Advanced Component Library",
                            "content": "Have full control over the style and consistency of all visual and interactive elements, with advanced options like shoppable images, hover galleries, and product comparison tools, allowing you to build storefronts exactly as you envision.",
                            "pic": "5_Online_store_content_image",
                            "btns": [
                                {
                                    "title": "Learn more",
                                    "link": "https://help.shopline.com/hc/en-001/articles/15525510786201-Guide-to-Advanced-Component-Library"
                                },
                                {
                                    "title": "Install Advanced Component Library",
                                    "link": "https://apps.shopline.com/detail/?appHandle=advanced_component_library"
                                }
                            ]
                        },
                        {
                            "title": "Get into the code",
                            "content": "Directly edit the CSS, HTML, and JavaScript, or leverage Handlebars or the Storefront API for more advanced configurations..",
                            "pic": "6_Online_store_content_image",
                            "btns": [
                                {
                                    "title": "Get started",
                                    "link": "https://developer.shopline.com/docsv2/ec20/hgr7vj9rpaah1a6m/n37zruz5pcban9fa?version=v20241201"
                                }
                            ]
                        }
                    ],
                    "id": "2_2"
                },
                {
                    "title": "SEO",
                    "tabs": [
                        {
                            "title": "SEO diagnostics and optimization",
                            "content": "Automatically perform SEO audits and generate detailed performance reports, complete with optimization suggestions for meta tags, keywords, readability, content relevance, and more to boost your SEO performance.",
                            "pic": "7_Online_store_content_image",
                            "btns": [
                                {
                                    "title": "Learn more",
                                    "link": "https://help.shopline.com/hc/en-001/articles/27495369622553-Configuring-Basic-SEO-Settings-with-EasyRank-SEO#h_01J8EEXA16V7DDRJF6CMXSAKDK"
                                },
                                {

                                    "title": "Install EasyRank SEO",
                                    "link": "https://apps.shopline.com/detail/?appHandle=seo_search_engine_optimization_2_0"
                                }
                            ]
                        },
                        {
                            "title": "Keyword analysis",
                            "content": "Easily analyze the keyword performance on your site, identify new targets, and generate insights to increase SEO traffic.",
                            "pic": "8_Online_store_content_image",
                            "btns": [
                                {
                                    "title": "Learn more",
                                    "link": "https://help.shopline.com/hc/en-001/articles/38061259507481-Guide-to-Advanced-SEO-Settings-with-EasyRank-SEO#h_01J8FDSM5FZV5CKSP5VDZ5JM1F"
                                },
                                {
                                    "title": "Watch video",
                                    "link": "https://www.youtube.com/watch?v=0WZ0mZG7QYE"
                                }
                            ]
                        },
                        {
                            "title": "Automated structured data",
                            "content": "Automatically generate structured data markups, including product information, reviews, and return policies, to boost visibility and display richer search results on Google SERPs.",
                            "pic": "9_Online_store_content_image",
                            "btns": [
                                {
                                    "title": "Learn more",
                                    "link": "https://help.shopline.com/hc/en-001/articles/38061259507481-Guide-to-Advanced-SEO-Settings-with-EasyRank-SEO#h_01J8FDQRKY9M21BNAXBN55SM6X"
                                }
                            ]
                        },
                        {
                            "title": "Customizable URL structure",
                            "content": "Customize SEO-friendly URLs with target keywords to enhance site navigation and improve indexing.",
                            "pic": "10_Online_store_content_image",
                            "btns": [
                                {
                                    "title": "Learn more",
                                    "link": "https://help.shopline.com/hc/en-001/articles/33749590631961-URL-Structure-Settings"
                                }
                            ]
                        },
                        {
                            "title": "Customizable sitemap",
                            "content": "Automatically generate a sitemap.xml with sub-sitemaps for products, collections, blogs, and custom pages, while flexibly prioritizing key content and organizing multi-language sites for better search engine navigation.",
                            "pic": "11_Online_store_content_image",
                            "btns": [
                                {
                                    "title": "Learn more",
                                    "link": "https://help.shopline.com/hc/en-001/articles/900004664046-Submitting-A-Sitemap-to-Google-Search-Console"
                                }
                            ]
                        }
                    ],
                    "id": "2_3"
                },
                {
                    "title": "Performance",
                    "tabs": [
                        {
                            "title": "Faster load times",
                            "content": "All SHOPLINE store(s) are deployed across vast edge networks (CDN) to ensure the quickest load speed anywhere in the world, even during holiday spikes.",
                            "pic": "12_Online_store_content_image"
                        },
                        {
                            "title": "Enhanced mobile web performance",
                            "content": "Optimized mobile website responsiveness for improved user experience and higher conversion across all shopping interactions.",
                            "pic": "13_Online_store_content_image"
                        },
                        {
                            "title": "Security",
                            "content": "SHOPLINE is ISO27001 certified and compliant with SOC 2 Type II standards. All SHOPLINE store(s) are always protected against zero-days, DDoS, SQL injections and other threats with the latest WAF technologies.",
                            "pic": "14_Online_store_content_image",
                            "btns": [
                            {
                                "title": "Find out more",
                                "link": "https://www.shopline.com/compliance-center/soc"
                            }
                        ]
                        }
                    ],
                    "id": "2_4"
                }
            ],
            "ai": {
                "id": "2_5",
                "title": "Online Store",
                "subtitle": "Build with AI",
                "content": [
                    {
                        "title": "AI store builder",
                        "content": "Instantly generate an entire digital storefront that matches your brand and design preferences, in just minutes.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/24106787322905-Creating-Your-Store-with-AI-in-One-Click"
                            }
                        ]
                    },
                    {
                        "title": "AI content generator",
                        "content": "Instantly generate full [landing pages](https://help.shopline.com/hc/en-001/articles/20535031131545-Crafting-Landing-Pages-with-AI-Powered-Copywriting#h_01HXE29V8TGDQAZ1MN75GZ1K9C), blog articles, [campaign messaging](https://help.shopline.com/hc/en-001/articles/15528888042393-Guide-to-SmartPush#h_01HT1NCRHN5RG40QC8S5PCHK9T), [product descriptions](https://help.shopline.com/hc/en-001/articles/18649507249177-Crafting-Product-Descriptions-Using-SHOPLINE-s-AI-Generator), and determine purchase intent during [live shopping](https://help.shopline.com/hc/en-001/articles/27385437450009-Enabling-AI-powered-Identification-and-Categorization-of-Comment-Intents), in over 30 languages.",
                        "btns": []
                    }
                ],
                "pic": "15_Online_store_content_image"
            },
            "operational_excellence": {
                "title": "Online Store",
                "subtitle": "Achieve Operational Excellence Effortlessly",
                "content": "While SHOPLINE Online Store makes it quick and easy to build your store, its suite of integrated  features help you effectively manage the operational parts of your business with ease.  Check out some of our new releases below.",
                "pic": "16_Online_store_content_image",
                "id": "2_6"
            },
            "product_management": {
                "id": "2_7",
                "title": "Online Store",
                "subtitle": "Product Management",
                "content": [
                    {
                        "title": "Smart collection",
                        "content": "Automatically generate product collections leveraging AI algorithms and your pre-set conditions. ",
                        "pic": "17_Online_store_content_image",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/14395898015513-Creating-Product-Collections#h_01J72FYMFMFE9EAC0PDWRH2ECA"
                            }
                        ]
                    },
                    {
                        "title": "Scheduled publishing",
                        "content": "Pre-schedule products to be published across different sales channels, including your online store, point-of-sale and mobile app.",
                        "pic": "18_Online_store_content_image",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/14357538994329-Configuring-Product-Details-and-Attributes#h_01J6GW9N1PEWPXN5NRSV1VZSRV"
                            }
                        ]
                    }
                ],
                "content2": [
                    {
                        "title": "SPU ID",
                        "content": "Streamline inventory and supply chain management by grouping product variations under a single identifier, and delivering ads to a wider audience.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/14357538994329-Configuring-Product-Details-and-Attributes#h_01J6GW9N1PEWPXN5NRSV1VZSRV"
                            }
                        ]
                    },
                    {
                        "title": "Bulk edits",
                        "content": "Edit large volumes of products simultaneously in the dashboard, including updates to product status, tags, available sales channels, and more.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/14390381853337-Product-Bulk-Operation"
                            }
                        ]
                    },
                    {
                        "title": "SKU Generator",
                        "content": "Generate SKU identifiers in bulk with flexible rule combinations such as product or product name, custom texts and more.",
                        "btns": [
                            {
                                "title": "Install SKU Generator",
                                "link": "https://apps.shopline.com/detail/?appHandle=sku_generator"
                            },
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/15525602534553-Automatically-Generating-SKUs-with-SKU-Generator"
                            }
                        ]
                    }
                ]
            },
            "merchandising": {
                "id": "2_8",
                "title": "Online Store",
                "subtitle": "Merchandising Tools",
                "content": [
                    {
                        "title": "Smart Search",
                        "content": "Dynamically display relevant results (product, collection, blog, etc) based on search intent, synonym matching, keyword redirections, and more.",
                        "pic": "19_Online_store_content_image",
                        "btns": [
                            {
                                "title": "Install Smart Search",
                                "link": "https://apps.shopline.com/detail/?appHandle=smart_search_recommendation"
                            },
                            {
                                 "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/5897824213913-Smart-Search-Recommendation#h_01HCHCA3AVA1RAYQQG2W4RPE70"
                            }
                        ]
                    },
                    {
                        "title": "Variant image carousels",
                        "content": "A dedicated image carousel for each product variant (color, style, etc), showcasing multiple angles and details of the variant.",
                        "pic": "20_Online_store_content_image",
                        "btns": [
                            {
                                "title": "Install Image Carousels",
                                "link": "https://apps.shopline.com/detail/?appHandle=variants_multiple_images&lang=en"
                            }
                        ]
                    },
                    {
                        "title": "Variants Combination",
                        "content": "Seamlessly display complementary product suggestions below the main product page, allowing customers to easily explore and switch between products.",
                        "pic": "21_Online_store_content_image",
                        "btns": [
                            {
                                "title": "Install Variants Combination",
                                "link": "https://apps.shopline.com/detail/?appHandle=variant_combination"
                            },
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/16717141552537-Creating-Product-Combos-with-the-Variants-Combination-App"
                            }
                        ]
                    }
                ],
                "content2": [
                    {
                        "title": "Mega Menu Builder",
                        "content": "Customizable, multi-level dropdown menu that allows online stores to organize categories, subcategories, images, and promotional content that streamlines product discovery.",
                        "btns": [
                            {
                                "title": "Install Mega Menu",
                                "link": "https://apps.shopline.com/detail/?appHandle=mega_menu_builder"
                            },
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/9854271346713-Guide-to-Mega-Menu-Builder"
                            }
                        ]
                    },
                    {
                        "title": "Advanced product displays",
                        "content": "Customize the order in which products are displayed within each collection based on advanced sorting rules, to increase conversions and reduce cart abandonment.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/14395898015513-Creating-Product-Collections#h_01J72FZMC5FCSQHXP0FEQZVSD5"
                            }
                        ]
                    },
                    {
                        "title": "Duplicate size charts",
                        "content": "Instantly setup size charts that can be applied across multiple storefronts and mobile app.",
                        "btns": [
                            {
                                "title": "Install Size Chart",
                                "link": "https://apps.shopline.com/detail/?appHandle=size_chart"
                            }
                        ]
                    }
                ]
            },
            "promotion_engine": {
                "id": "2_9",
                "title": "Online Store",
                "subtitle": "Promotion Engine",
                "content": [
                    {
                        "title": "Custom discount types",
                        "content": "Create and offer over 100 discount combinations that range from waived shipping, buy X and get Y free, discount on Nth item, and more across multiple sales channels.",
                        "pic": "22_Online_store_content_image",
                        "btns": [
                            {
                                "title": "Watch video",
                                "link": "https://www.youtube.com/watch?v=5nzGBZg-Ha8"
                            },
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/26948171525273-Discounts-Overview"
                            }
                        ]
                    },
                    {
                        "title": "Flash Sales",
                        "content": "Offer discounts on individual products or collections for a limited time period for seasonal purchases or stock clearance.",
                        "pic": "23_Online_store_content_image",
                        "btns": [
                            {
                                "title": "Install Flash Sales",
                                "link": "https://apps.shopline.com/detail/?appHandle=flash_sales"
                            },
                            {
                                 "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/14203562920089-Setting-up-Flash-Sales"
                            }
                        ]
                    },
                    {
                        "title": "Discount stacking",
                        "content": "Offer customers greater savings through a flexible promotion stacking mechanism.",
                        "pic": "24_Online_store_content_image",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/14128068047001-Discounts-Stacked-Discounts"
                            }
                        ]
                    }
                ]
            },
            "inventory_management": {
                "id": "2_10",
                "title": "Online Store",
                "subtitle": "Inventory Management",
                "content": [
                    {
                        "title": "Inventory status",
                        "content": "Precise tracking and breakdown of inventory levels–on-hand, available, committed, unavailable, for easier monitoring.",
                        "pic": "25_Online_store_content_image",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/14579691063321-Tracking-and-Managing-Product-Inventory#h_01HR90XMPGXNPJGQE72DYA4TKV"
                            }
                        ]
                    },
                    {
                        "title": "Inventory log",
                        "content": "Accurately log all product movements (sales, transfers, purchase orders, returns/cancellations, etc) across different channels and store locations in real-time, ensuring that quantities are always accurate and up-to-date.",
                        "pic": "26_Online_store_content_image",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/14579691063321-Tracking-and-Managing-Product-Inventory#h_01HR90XMPHGKGGKXKDKE7HMFC9"
                            }
                        ]
                    },
                    {
                        "title": "Dynamic inventory allocation",
                        "content": "Real-time, dynamic inventory adjustments for your online and/or physical stores to ensure all channels are optimally stocked, and to prevent stockouts.",
                        "pic": "27_Online_store_content_image",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/26333194095769-Sales-Inventory-Plan-and-Product-List"
                            }
                        ]
                    },
                    {
                        "title": "Reserved inventory",
                        "content": "Create custom rules to reserve inventory for [live selling](https://help.shopline.com/hc/en-001/articles/24888299883929-Reserving-Products-for-Customers#h_01HGA3NT330F4Y7FHHHA039DCD), draft orders, and [simultaneous checkouts](https://help.shopline.com/hc/en-001/articles/20024302623129-Locking-Inventory-When-Customers-Check-out).",
                        "pic": "28_Online_store_content_image",
                        "btns": []
                    }
                ]
            },
            "order_management": {
                "id": "2_11",
                "title": "Online Store",
                "subtitle": "Order & Fulfillment",
                "content": [
                    {
                        "title": "SEEL integration",
                        "content": "SHOPLINE is integrated with SEEL to provide your customers with guaranteed ETAs, shipment protections, hassle free returns, and a worry free shopping experience.  ",
                        "pic": "29_Online_store_content_image",
                        "btns": [
                            {
                                "title": "Install Guaranteed Delivery",
                                "link": "https://apps.shopline.com/detail/?appHandle=guaranteed_delivery"
                            },
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/33609373756825-Offering-Guaranteed-Delivery-with-Shipping-Insurance"
                            }
                        ]
                    },
                    {
                        "title": "Draft order",
                        "content": "Create and save orders for future processing, inventory reservation, or custom orders, providing flexibility and control before completing a transaction.",
                        "pic": "30_Online_store_content_image",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/14580386937241-Creating-Draft-Orders"
                            },
                            {
                                "title": "Watch video",
                                "link": "https://www.youtube.com/watch?v=9biXeH_54vQ&list=PLq_mgGsNAhDgmoamHhG4eMa8Gs7rhtlnH&index=15"
                            }
                        ]
                    }
                ],
                "content2": [
                    {
                        "title": "Abandoned checkout",
                        "content": "Automatically monitor for potential cart abandonment and send personalized reminders to encourage customers to complete the transaction.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/4402082713753-Abandoned-Checkouts"
                            }
                        ]
                    },
                    {
                        "title": "Change fulfillment location",
                        "content": "Easily update shipping locations and/or revert to original location, after an order has been placed.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/39523067548185-Changing-Locations-and-Suspending-Shipments-for-Unshipped-Orders#h_01JBKWWW2FT1B1FWV501D8K06E"
                            }
                        ]
                    }
                ]
            },
            "checkout_payment": {
                "id": "2_12",
                "title": "Online Store",
                "subtitle": "Checkout & Payment",
                "content": {
                    "content": "According to H1 2024 data, the average conversion rate for SHOPLINE stores is 1.9%. By implementing SHOPLINE’s native conversion-driven solutions, enterprise-level merchants managed to achieve an improved average conversion rate of 4.3%."
                },
                "content2": [
                    {
                        "number": "1.9",
                        "title": "Average visit to order conversion rate"
                    },
                    {
                        "number": "4.3",
                        "title": "Improved visit to order conversion rate"
                    },
                    {
                        "number": "18",
                        "title": " Higher success rate with SHOPLINE Payments"
                    }
                ],
                "content3": [
                    {
                        "title": "Buy with Prime (BwP)",
                        "content": "Enable customers to complete transactions via their Amazon Prime account, while taking advantage of the benefits (e.g. free 2 day shipping) included in their Prime membership. ",
                        "pic": "31_Online_store_content_image",
                        "btns": [
                            {
                                "title": "Get in touch",
                                "link": "form"
                            }
                        ]
                    },
                    {
                        "title": "Express checkout options",
                        "content": "Expedite the checkout process by leveraging stored payment and shipping information across all payment options, such as Apple Pay, Google Pay, PayPal and more. ",
                        "pic": "32_Online_store_content_image",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/900005341603-Boosting-Sales-with-One-Click-Checkout-Buttons#h_01HT6ZPA7K3MH0CFP4KZJ0QWWN"
                            }
                        ]
                    },
                    {
                        "title": "SHOPLINE Payments",
                        "content": "Native, PCI-DSS level 1 certified,  payment gateway to securely manage on and offline payments across different methods and channels. Securely process payments with SHOPLINE’s exclusive fraud detection AI.",
                        "pic": "33_Online_store_content_image",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://www.shopline.com/checkout-and-payments"
                            }
                        ]
                    }
                ],
                "content4": [
                    {
                        "title": "Checkout editor",
                        "content": "Easily make custom configurations to optimize the checkout experience for your customers.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/18296752573977-Customizing-Your-Store-Checkout-Pages"
                            }
                        ]
                    },
                    {
                        "title": "Checkout extension",
                        "content": "Personalize the checkout experience with added functionalities such as suggesting related products, special offers, redeem points, and more. ",
                        "btns": [
                            {
                                "title": "Get in touch",
                                "link": "form"
                            }
                        ]
                    }
                ]
            },
            "analytics": {
                "id": "2_13",
                "title": "Online Store",
                "subtitle": "Analytics",
                "content": [
                    {
                        "title": "Analytics across different metrics",
                        "content": "Over 70 pre-built reports for tracking sales, customer behavior, online store traffic, inventory, marketing intelligence, and more to help you make data-driven decisions.",
                        "pic": "34_Online_store_content_image",
                        "btns": [
                            {
                                "title": "Watch video",
                                "link": "https://www.youtube.com/watch?v=W7SCS1RTYvk&list=PLq_mgGsNAhDgmoamHhG4eMa8Gs7rhtlnH&index=17"
                            },
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/4415642026009-Analyzing-Your-Store-Data-with-Reports"
                            }
                        ]
                    },
                    {
                        "title": "In-depth funnel analysis",
                        "content": "Effectively monitor and track the different stages of conversion across different global regions, devices, campaigns, and more with our pre-built funnel dashboard. ",
                        "pic": "35_Online_store_content_image",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/39161602943897-Understanding-Conversion-Funnel-Analysis"
                            }
                        ]
                    },
                    {
                        "title": "Sell-through rate",
                        "content": "Easily measure and assess product and inventory performance to optimize your pricing, purchasing and discounting strategies.",
                        "pic": "36_Online_store_content_image",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/25421858015897-Inventory-Reports#h_01HTHJPGRFX1XH5M60K24H1VBP"
                            }
                        ]
                    },
                    {
                        "title": "Live view",
                        "content": "Real-time data provides you with insights on the performance of the most important aspects of your business, ranging from specific markets, down to specific key events that matter.",
                        "pic": "37_Online_store_content_image",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/900005803303-Live-View"
                            }
                        ]
                    }
                ]
            },
            "success_story": {
                "id": "2_14",
                "title": "Success Story",
                "subtitle": "Bricks World: LEGO Certified Store Retailer",
                "content": "“Partnering with SHOPLINE helped us tackle the operational hurdles of running separate online and POS systems.\n\nTheir integration has streamlined our processes and allowed us to focus on delivering seamless customer experiences, enhancing our customer service and maintaining our competitive edge in the retail industry.”\n\n-Bricks World Team",
                "pic": "38_Online_store_content_image",
                "pic2": "39_Online_store_content_image",
                "btns": [
                    {
                        "title": "Find out more",
                        "link": "https://www.shopline.com/omo"
                    }
                ]
            }
        },
        "integrated_channels": {
            "overview": {
                "id": "3_1",
                "title": "Integrated Channels",
                "subtitle": "Create Elevated Shopping Experiences",
                "content": "Create elevated, unique shopping experiences by embracing flexible, omni-channel strategies that seamlessly integrate online, in-store, and mobile interactions, ensuring a cohesive and personalized brand experience across all customer touchpoints.",
                "pic": "40_Elevated_Shopping_experiences_1"
            },
            "retail_pos": {
                "id": "3_2",
                "title": "Integrated Channels",
                "subtitle": "Retail POS",
                "v1": "Fashion_en.webm",
                "v2": "https://www.youtube.com/watch?v=5A01MmF9oGY&list=PLq_mgGsNAhDg6N7FeKdcvTGt_WLsEYuAV&index=13",
                "content": [
                    {
                        "title": "Elevated in-store experiences",
                        "content": "Access unified customer profiles to provide personalized services and recommendations, reward redemption, saved carts — all from the SHOPLINE POS.",
                        "pic": "42_Elevated_Shopping_experiences",
                        "btns": [
                            {
                                "title": "Install POS",
                                "link": "https://apps.shopline.com/detail/?appHandle=pos&lang=en"
                            },
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/26035267607449-SHOPLINE-POS-Overview"
                            }
                        ]
                    },
                    {
                        "title": "Smart Inventory Management",
                        "content": "Effectively manage inventory directly from your POS, with smart reordering, automated stock alerts, inventory transfers, and more.",
                        "pic": "43_Elevated_Shopping_experiences",
                        "btns": [
                            {
                                "title": "Install Smart Inventory",
                                "link": "https://apps.shopline.com/detail/?appHandle=smart_inventory_management"
                            },
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/sections/36667329020953-Smart-Inventory-Management"
                            }
                        ]
                    },
                    {
                        "title": "Multi-store management",
                        "content": "Manage the operations and performance, stock levels, pricing, and store-level reports of multiple stores, all from a single integrated POS portal.",
                        "pic": "44_Elevated_Shopping_experiences",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/18284317922585-Setting-Location-Based-Pricing-for-Different-Stores"
                            }
                        ]
                    },
                    {
                        "title": "Easy payment setup",
                        "content": "With SHOPLINE Payments and third party payment gateways already integrated, you will instantly have access to multiple payment selections, multi-currency support, and split payment options.",
                        "pic": "45_Elevated_Shopping_experiences",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/26162202237849-Guide-to-Setting-up-POS-Payment-Methods"
                            }
                        ]
                    }
                ],
                "content2": [
                    {
                        "title": "Digital receipts",
                        "content": "Send paperless receipts to customers through email, SMS, Whatsapp and more.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/18382549728281-POS-Sending-Receipts-to-Customers"
                            }
                        ]
                    },
                    {
                        "title": "Flexible hardware options",
                        "content": "Leverage one of the many SHOPLINE preferred hardware vendors, or simply use any iPad or Android tablets.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/18360904450201-Compatible-Hardware-List-for-SHOPLINE-POS"
                            }
                        ]
                    },
                    {
                        "title": "Online-merge-offline (OMO)",
                        "content": "Offer customers flexible shopping experiences including online ordering with in-store pickup, the ability to schedule preferred pickup and delivery times, access to digital customer profiles from any device (including the POS), and more.",
                        "btns": [
                            {
                                "title": "Find out more",
                                "link": "https://www.shopline.com/omo"
                            }
                        ]
                    }
                ]
            },
            "success_story": {
                "id": "3_3",
                "title": "Success Story",
                "subtitle": "Sunnystep: Doubling Store Presence",
                "content": "Learn how this footwear brand successfully integrates an OMO strategy to drive exponential growth.",
                "pic": "46_Elevated_Shopping_experiences",
                "v1": "47_Elevated_Shopping_experiences.webp",
                "v2": "",
                "btns": [
                    {
                        "title": "Read now",
                        "link": "https://shorturl.at/0YCEG"
                    }
                ]
            },
            "mobile_experience": {
                "id": "3_4",
                "title": "Integrated Channels",
                "subtitle": "Elevated Mobile Experience",
                "content": {
                    "content": "Easily build and deploy a custom native mobile shopping app, providing your customers with an even more convenient and engaging way to shop. Your app will be fully integrated with all the SHOPLINE core functionalities and seamlessly sync with all customer data and intelligence captured across all shopping channels.",
                    "pic": "48_Elevated_Shopping_experiences",
                    "btns": [
                        {
                            "title": "Find out more",
                            "link": "https://www.shopline.com/shopperapp"
                        }
                    ]
                },
                "content2": [
                    {
                        "title": "No-code editor",
                        "content": "Easily build a custom, native iOS or Android shopping app for your brand, leveraging our drag and drop editor and widgets library.",
                        "pic": "49_Elevated_Shopping_experiences",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/25323164609433-Designing-Your-App-Homepage"
                            }
                        ]
                    },
                    {
                        "title": "Push notifications",
                        "content": "Send unlimited scheduled or trigger-based personalized push notifications through the app.",
                        "pic": "50_Elevated_Shopping_experiences",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/25315634881049-Setting-Up-Push-Notifications"
                            }
                        ]
                    },
                    {
                        "title": "App-only discounts",
                        "content": "Offer exclusive discounts available only through the app to keep customers engaged and incentivize repeat purchases.",
                        "pic": "51_Elevated_Shopping_experiences",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/37581503808153-Setting-up-App-exclusive-Offerings"
                            }
                        ]
                    },
                    {
                        "title": "App-first product launches and drops",
                        "content": "Offer your most loyal customers early access to product launches, collections, and drops in your mobile shopping app.",
                        "pic": "52_Elevated_Shopping_experiences",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/37581503808153-Setting-up-App-exclusive-Offerings#h_01J7GCCKJWF26HMTNVXKRNEC99"
                            }
                        ]
                    },
                    {
                        "title": "Mobile membership integration",
                        "content": "Allow customers to easily join your membership program and access member benefits through the app.",
                        "pic": "53_Elevated_Shopping_experiences",
                        "btns": [
                            {
                                "title": "Install Member System",
                                "link": "https://apps.shopline.com/detail/?appHandle=member_system"
                            }
                        ]
                    }
                ]
            },
            "multichannel_connect": {
                "id": "3_5",
                "title": "Integrated Channels",
                "subtitle": "Multichannel Connect",
                "content": {
                    "content": "Manage all marketplace channels, your online store, and key sales channels directly from SHOPLINE. With a centralized hub for inventory, orders, shipping, and more, streamline your entire marketplace operations effortlessly.",
                    "pic": "54_Elevated_Shopping_experiences",
                    "btns": [
                            {
                                "title": "Install Multichannel Connect",
                                "link": "https://apps.shopline.com/detail/?appHandle=multichannel_connect"
                            }
                        ]
                },
                "content2": [
                    {
                        "title": "Integrated marketplaces",
                        "content": "Manage sales with ease on Amazon, eBay, TikTok Shop, Shopee, Lazada and more from a centralized platform.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/37621844935193-Multichannel-Connect-Overview"
                            }
                        ]
                    },
                    {
                        "title": "Flexible inventory strategies",
                        "content": "List products and apply shared or custom inventory strategies for each marketplace to maximize performance.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/26139838177305-Enabling-Shared-Inventory"
                            }
                        ]
                    },
                    {
                        "title": "Customer data sync",
                        "content": "Select what customer and order data to automatically sync back to your SHOPLINE database.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/17644811068057-Importing-Multichannel-Customer-Information-to-SHOPLINE"
                            }
                        ]
                    }
                ]
            }
        },
        "meaningful_engagements": {
            "overview": {
                "id": "4_1",
                "title": "Meaningful Engagements",
                "subtitle": "Build Loyalty Through Engagement",
                "content": "Drive more meaningful customer engagements and accelerate conversions across all channels with SHOPLINE’s suite of integrated, first-part data-driven apps.",
                "pic": "55_Meaningful_engagements"
            },
            "smart_push": {
                "id": "4_2",
                "title": "Meaningful Engagements",
                "subtitle": "Marketing Automation with SmartPush",
                "v1": "56_Meaningful_engagements_1.webp",
                "v2": "",
                "content": [
                    {
                        "title": "Email",
                        "content": "Create highly personalized trigger-based or promotional email campaigns with 99.9% deliverability.\n[\\disc]Use A/B testing to compare subject lines, designs, and content variations\n[\\disc]AI-optimized timing determines the best time to send emails\n[\\disc]Dynamic product recommendations based on user behavior",
                        "pic": "57_Meaningful_engagements_1",
                        "btns": [
                            {
                                "title": "Install SmartPush",
                                "link": "https://apps.shopline.com/detail/?appHandle=smartpush"
                            },
                            {
                                "title": "Find out more",
                                "link": "https://www.shopline.com/smartpush"
                            }
                        ]
                    },
                    {
                        "title": "SMS",
                        "content": "Have a more meaningful conversation with automated, hyper-personalized SMS campaigns.\n[\\disc]Event or Behavior-Based Triggers\n[\\disc]Target Key Moments",
                        "pic": "58_Meaningful_engagements_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://smartpushteam.zendesk.com/hc/en-001/articles/14753102778391-SMS-Text-Message-Marketing-The-Next-Powerful-Touchpoint"
                            }
                        ]
                    },
                    {
                        "title": "Pop-ups",
                        "content": "Engage customers at the moments that matter with dynamic pop-ups.\n[\\disc]Easily create web and in-app pop-ups with 100+ customizable templates\n[\\disc]Reduce cart abandonment\n[\\disc]Easily collect customer data",
                        "pic": "59_Meaningful_engagements_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://smartpushteam.zendesk.com/hc/en-001/articles/17488700561559-Pop-up-form-myshopline-com"
                            }
                        ]
                    }
                ]
            },
            "member_system": {
                "id": "4_3",
                "title": "Meaningful Engagements",
                "subtitle": "Loyalty - Member System",
                "v1": "Fashion_en.webm",
                "v2": "https://www.youtube.com/watch?v=5A01MmF9oGY&list=PLq_mgGsNAhDg6N7FeKdcvTGt_WLsEYuAV&index=13",
                "content": [
                    {
                        "title": "Tiered-based reward programs",
                        "content": "Enable customers to earn rewards based on their level of engagement and/or the amount they spend. Leverage built-in AI features to create personalized tiers to maximize profit margins.",
                        "pic": "61_Meaningful_engagements_1",
                        "btns": [
                            {
                                "title": "Install Member System",
                                "link": "https://apps.shopline.com/detail/?appHandle=member_system"
                            },
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/4408081387673-Setting-up-Membership-Levels"
                            }
                        ]
                    },
                    {
                        "title": "Multi-channel points and rewards",
                        "content": "Enable customers to effortlessly earn, accumulate, and redeem rewards across all shopping channels, online or in-store. Moreover, provide customers with options to combine points with cash at checkout.",
                        "pic": "62_Meaningful_engagements_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/39258437239577-Redeeming-Customer-Points-for-Products-Using-Member-PoinShop"
                            }
                        ]
                    },
                    {
                        "title": "Shopping credits for refunds",
                        "content": "Create flexible credit rules to offer shopping credits instead of cash refunds, driving repeat purchases.",
                        "pic": "63_Meaningful_engagements_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/9069062921241-Setting-up-Store-Credits#h_01HX4KWVHXNSJ6CPC2X9HN1X0N"
                            }
                        ]
                    },
                    {
                        "title": "Personalized branding",
                        "content": "Tailor your member panel and landing pages to create a fully branded, personalized experience.",
                        "pic": "64_Meaningful_engagements_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/14975159194777-Customizing-Engaging-Member-Pop-up-Panel"
                            }
                        ]
                    },
                    {
                        "title": "Real-time member data API",
                        "content": "Easily retrieve and update customer information such as membership details, reward statuses, and store credit balances, integrating with your existing systems.",
                        "pic": "65_Meaningful_engagements_1",
                        "btns": [
                            {
                                "title": "Get started",
                                "link": "https://developer.shopline.com/docsv2/ec20/2c36f588a7f4ea1184a9af90b82f23be/bc2c06661f7d5f21f353d1814a6f93db?version=v20241201"
                            }
                        ]
                    }
                ],
                "content2": [
                    {
                        "title": "Nudges",
                        "content": "Automatically remind customers to earn or use rewards with pop-ups on your site or mobile app, driving engagement at key moments.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/12351776634777-Setting-up-Nudges"
                            }
                        ]
                    },
                    {
                        "title": "Digital reward cards",
                        "content": "Easily retrieve member information, earn and redeem rewards by scanning member ID, streamlining in-store checkout experience.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/37669384681625-Enabling-Digital-Member-Cards"
                            }
                        ]
                    },
                    {
                        "title": "Referrals and sign-ups",
                        "content": "Create programs to incentivize customers to opt into promotions or refer a friend.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/4408073573657-Setting-up-and-Managing-a-Referral-Program"
                            }
                        ]
                    }
                ]
            },
            "actionale_insights": {
                "id": "4_4",
                "title": "Meaningful Engagements",
                "subtitle": "Actionable Insights",
                "content": [
                    {
                        "title": "Funnel analysis",
                        "content": "Dive deep into understanding your end-to-end customer journey, conduct cohort analysis, identify opportunities to increase conversions, and more.",
                        "pic": "66_Meaningful_engagements_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/37567537698585-Understanding-Funnel-Analysis"
                            }
                        ]
                    },
                    {
                        "title": "Events analysis",
                        "content": "Create custom reports and conduct a thorough analysis to identify patterns, trends, and opportunities to drive more meaningful customer engagements.",
                        "pic": "67_Meaningful_engagements_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/33379184668313-Understanding-Event-Analysis"
                            }
                        ]
                    },
                    {
                        "title": "Campaign metrics",
                        "content": "Understand how your campaigns and channels are performing, and create custom reports with your own UTM values.",
                        "pic": "68_Meaningful_engagements_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/25421874830617-Insights-Report#h_01JBP0C4RE0X62QM6CAJT3WHJR"
                            }
                        ]
                    },
                    {
                        "title": "Advertisement attribution analysis",
                        "content": "Fully understand which customer touch points and ad channels are your key business drivers.",
                        "pic": "69_Meaningful_engagements_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/33179228529177-Understanding-Attribution-Reports"
                            }
                        ]
                    }
                ]
            }
        },
        "social_commerce": {
            "overview": {
                "id": "5_1",
                "title": "Social Commerce",
                "subtitle": "Transform Social Selling Experiences",
                "content": "Reach, engage, and convert customers on their favorite social platforms. Enhance your social strategy by offering even more convenient and interactive shopping experiences across the most popular social platforms and messaging apps.",
                "btns": [
                            {
                                "title": "Find out more",
                                "link": "https://www.shopline.com/social-commerce/features#section_bio-tool"
                            }
                        ],
                "pic": "70_Social_Commerce_1"
            },
            "social_ads": {
                "id": "5_2",
                "title": "Social Commerce",
                "subtitle": "Social Ads",
                "content": [
                    {
                        "title": "Meta integration",
                        "content": "Drive better conversion for ads and improve ROAS with rich API integrations, comprehensive client and server-side reporting and high quality reporting on click_id, browser_id and external_id.",
                        "pic": "71_Social_Commerce_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/9586269233945-Facebook-Pixel-Definition-Creation-Flow-Asset-Authorization-and-FAQs"
                            }
                        ]
                    },
                    {
                        "title": "Meta CAPI",
                        "content": "Seamlessly connect customer, event, performance data and more across all on and offline channels to enable more accurate attribution reporting, funnel monitoring and enhanced targeting for your Facebook and Instagram ads.",
                        "pic": "72_Social_Commerce_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/4404028344345-Facebook-Data-Reporting-FAQs-for-SHOPLINE-Merchants#h_01HXE7HDDYB6DWN6NZ8AQ7DGWE"
                            }
                        ]
                    },
                    {
                        "title": "Smart Feed",
                        "content": "Keep your product listings up-to-date on advertising channels like Facebook, Instagram, and Snapchat through real-time synchronization from SHOPLINE.",
                        "pic": "73_Social_Commerce_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/37774302098841-Introducing-Smart-Feed"
                            },
                            {
                                "title": "Install Smart Feed",
                                "link": "https://apps.shopline.com/detail/?appHandle=smart_feed_prod"
                            }
                        ]
                    },
                    {
                        "title": "Unified social media integration",
                        "content": "Manage ads across [Facebook](https://help.shopline.com/hc/en-001/articles/4407066539801-Selling-and-Marketing-on-Facebook-with-SHOPLINE), [Instagram](https://help.shopline.com/hc/en-001/articles/4407066539801-Selling-and-Marketing-on-Facebook-with-SHOPLINE), [Tiktok](https://help.shopline.com/hc/en-001/articles/5781433197465-Guide-to-TikTok-Marketing), and [Google](https://help.shopline.com/hc/en-001/articles/4407116537881-Google-Channel-Overview) within a single platform, ensuring consistent logic and better compatibility with SHOPLINE.",
                        "pic": "74_Social_Commerce_1"
                    }
                ]
            },
            "affiliate_marketing": {
                "id": "5_3",
                "title": "Social Commerce",
                "subtitle": "Affiliate Marketing",
                "content": {
                    "content": "SHOPLINE streamlines the process of managing and tracking influencer partnerships, enabling brands to effectively reach and engage customers across all social platforms. Easily automate link tracking, performance analytics, commission payouts, and more to scale your affiliate programs efficiently across Instagram, Facebook, Snapchat and other social platforms.",
                    "pic": "75_Social_Commerce_1",
                    "btns": [
                        {
                            "title": "Install Affiliate Marketing",
                            "link": "https://apps.shopline.com/detail/?appHandle=affiliate_marketing"
                        }
                    ]
                },
                "content2": [
                    {
                        "title": "Affiliate page styler",
                        "content": "A tool for affiliates to easily style their sales pages, incorporating brand colors, fonts, and layouts that resonate with their target audience.",
                        "pic": "76_Social_Commerce_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/39524024762137-Customizing-Affiliates-Personal-Page"
                            }
                        ]
                    },
                    {
                        "title": "Customizable affiliate discounts",
                        "content": "Create and offer specific discounts to incentivize customers to convert through affiliate referral links.",
                        "pic": "77_Social_Commerce_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/4409821078169-Setting-Up-Your-Affiliate-Discounts"
                            }
                        ]
                    }
                ],
                "content3": [
                    {
                        "title": "Affiliate referral links",
                        "content": "Auto-generate unique affiliate referral links to accurately attribute engagement events and sales directly to each affiliate.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/37851809567897-Introducing-Affiliate-Marketing"
                            }
                        ]
                    },
                    {
                        "title": "Multi-level commission distribution",
                        "content": "Enable affiliates to recruit sub-affiliates, forming a multi-level network that can span up to 20 levels for wide-reaching influence, and incentivize them with commissions for sub-affiliate referrals.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/14139985478553-Multi-level-Affiliate"
                            }
                        ]
                    },
                    {
                        "title": "Adaptive commission structure",
                        "content": "Implement flexible and customizable commission structures that calculate earnings based on different events, tiers, sales amounts, and more, to meet different conversion objectives.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/4409824422297-Establishing-Commission-Rates-and-Creating-Commission-Groups"
                            }
                        ]
                    },
                    {
                        "title": "Automated PayPal payout system",
                        "content": "Automatically capture and calculate affiliate campaign and referral performance and issue commission and bonus payments.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/23401109746329"
                            }
                        ]
                    }
                ]
            },
            "tiktok_shop": {
                "id": "5_4",
                "title": "Social Commerce",
                "subtitle": "TikTok Shop Integration",
                "content": {
                    "content": "Sell on TikTok Shop directly from your SHOPLINE store. The direct integration creates a seamless connection between your store and the most popular social platform, enabling you to directly sync products, process orders, capture customer data, and more.",
                    "btns": [
                            {
                                "title": "Install TikTok",
                                "link": "https://apps.shopline.com/detail/?appHandle=tiktok-business-plugin"
                            }
                        ],
                    "pic": "83_Social_Commerce_1"
                },
                "content2": [
                    {
                        "title": "TikTok multi-pixel plugin",
                        "content": "Accurately track and attribute events and conversions across all TikTok traffic sources with this Multi-Pixel plugin.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/4408171870489-Guide-to-TikTok-Multi-Pixel-Installation"
                            }
                        ]
                    },
                    {
                        "title": "Product synchronization",
                        "content": "Fully manage what products and product information (price, inventory status, etc.) are synced between your SHOPLINE store and TikTok Shop.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/22615289271321-Connecting-your-TikTok-Shop-to-SHOPLINE#h_01HFP67BVRFN7YKGG42SWQRB0S"
                            }
                        ]
                    },
                    {
                        "title": "Order synchronization",
                        "content": "Easily track and capture all information about orders processed on TikTok Shop directly in your SHOPLINE admin panel.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/22615289271321-Connecting-your-TikTok-Shop-to-SHOPLINE#h_01HFP6A34NKHNDR8ZZW53P2M44"
                            }
                        ]
                    }
                ]
            },
            "live_selling": {
                "id": "5_5",
                "title": "Social Commerce",
                "subtitle": "Live Selling",
                "v1": "Fashion_en.webm",
                "v2": "https://www.youtube.com/watch?v=5A01MmF9oGY&list=PLq_mgGsNAhDg6N7FeKdcvTGt_WLsEYuAV&index=13",
                "content": "Reach, engage, and convert customers on their favorite social platforms. Enhance your social strategy by offering even more convenient and interactive shopping experiences across the most popular social platforms and messaging apps.",
                "btns": [
                    {
                        "title": "Install Live",
                        "link": "https://apps.shopline.com/detail/?appHandle=live"
                    }
                ],
                "content2": [
                    {
                        "title": "Different campaign modes",
                        "content": "Pick and choose for different campaign modes such as drawings, bids, quick responses, or ‘comment for discounts.’",
                        "pic": "79_Social_Commerce_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/37527204879769-Elevating-Your-Livestream-Engagement-with-Events"
                            }
                        ]
                    },
                    {
                        "title": "Purchase by keyword",
                        "content": "Enable customers to directly make a purchase during the live shopping event by simply commenting with specific keywords you created.",
                        "pic": "80_Social_Commerce_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/900004292826-Starting-Your-Livestream-Sales-with-SHOPLINE#h_01HT46VNJRNM2PNZMTTHNX830N"
                            }
                        ]
                    },
                    {
                        "title": "Customer intent AI",
                        "content": "Detect customer intent and interest to purchase from comments in real time.",
                        "pic": "81_Social_Commerce_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/27385437450009-Enabling-AI-powered-Identification-and-Categorization-of-Comment-Intents"
                            }
                        ]
                    },
                    {
                        "title": "Reserved inventory",
                        "content": "Ensure sufficient inventory is available for customers participating in live shopping events.",
                        "pic": "82_Social_Commerce_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/24888299883929-Reserving-Products-for-Customers"
                            }
                        ]
                    }
                ],
                "content3": [
                    {
                        "title": "Multi-page streaming",
                        "content": "Simultaneously engage customers with live shopping across multiple Facebook Pages.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/900004292826-Starting-Your-Livestream-Sales-with-SHOPLINE"
                            }
                        ]
                    },
                    {
                        "title": "Live shopping intelligence",
                        "content": "Access performance reports for live shopping events to gain insights on sales, customer interactions, conversions, etc.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/sections/22614454195865-Reporting-and-Analytics"
                            }
                        ]
                    }
                ]
            },
            "chat_commerce": {
                "id": "5_6",
                "title": "Social Commerce",
                "subtitle": "Chat Commerce",
                "content": {
                    "content": "Turn conversations into conversions across different social messaging or chat apps with automated conversation experiences or live agents.",
                    "btns": [
                            {
                                "title": "Install Messages",
                                "link": "https://apps.shopline.com/detail/?appHandle=mc"
                            }
                        ],
                    "pic": "84_Social_Commerce_1"
                },
                "content2": [
                    {
                        "title": "Multi-channel integration",
                        "content": "Have direct conversations with customers on Facebook messenger, Instagram messenger, Whatsapp, Telegram, and other chat apps.",
                        "pic": "85_Social_Commerce_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/13519259305369-Message-Center-Connecting-to-Channels"
                            }
                        ]
                    },
                    {
                        "title": "SHOPLINE message center",
                        "content": "Manage all chat interactions across different channels in one single place.",
                        "pic": "86_Social_Commerce_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/14908432630553-Message-Center-Overview"
                            }
                        ]
                    },
                    {
                        "title": "Smart replies",
                        "content": "AI recommended responses to promptly respond to customer comments and questions.",
                        "pic": "87_Social_Commerce_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/10278504218521-Message-Center-Smart-Reply-Suggestions"
                            }
                        ]
                    },
                    {
                        "title": "Auto-replies",
                        "content": "Create specific auto replies that are triggered by specific keywords.",
                        "pic": "88_Social_Commerce_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/10862432371353-Message-Center-Chat-Assistant#h_01HNMN2XD4N1SET3J3K8C1TQY7"
                            }
                        ]
                    },
                    {
                        "title": "AI-identifiers",
                        "content": "Instantly identify and capture important customer information such as phone numbers and addresses in the chat with AI.",
                        "pic": "89_Social_Commerce_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/37655672013977-Improving-Efficiency-with-AI-Powered-Phone-and-Address-Identification"
                            }
                        ]
                    }
                ]
            },
            "success_story": {
                "id": "5_7",
                "title": "Success Story",
                "subtitle": "In The Style: #2 TikTok Ranking with Social Commerce",
                "content": "Learn how this UK online fashion retailer elevated their social commerce objectives with a multi-channel strategy.",
                "pic": "90_Social_Commerce_1",
                "v1": "Fashion_en.webm",
                "v2": "https://www.youtube.com/watch?v=5A01MmF9oGY&list=PLq_mgGsNAhDg6N7FeKdcvTGt_WLsEYuAV&index=13",
                "btns": [
                    {
                        "title": "Read now",
                        "link": "https://shorturl.at/0A7my"
                    }
                ]
            }
        },
        "extensibility": {
            "dev_tools": {
                "id": "6_1",
                "title": "Extensibility",
                "subtitle": "Developer Tools",
                "v1": "Fashion_en.webm",
                "v2": "https://www.youtube.com/watch?v=5A01MmF9oGY&list=PLq_mgGsNAhDg6N7FeKdcvTGt_WLsEYuAV&index=13",
                "content": [
                    {
                        "title": "Developer portal",
                        "content": "Get access to docs, API references, and other tools to build and integrate with the SHOPLINE commerce platform.",
                        "btns": [
                            {
                                "title": "Get started",
                                "link": "https://developer.shopline.com/"
                            }
                        ]
                    },
                    {
                        "title": "SHOPLINE CLI",
                        "content": "Leverage the SHOPLINE CLI to streamline development projects and workflow, debug, create new apps and more.",
                        "btns": [
                            {
                                "title": "Get started",
                                "link": "https://developer.shopline.com/docsv2/ec20/3cv5d7wpfgr6a8z5/3wvnxpx1eutnmx83?version=v20241201"
                            }
                        ]
                    }
                ],
                "subtitle2": "SHOPLINE APIs",
                "content2": [
                    {
                        "title": "Customer API",
                        "content": "Create more customer-centric and personalized experiences by seamlessly syncing customer data from SHOPLINE to your CRM, CDP, MAP, and other platforms. ",
                        "btns": [
                            {
                                "title": "Get started",
                                "link": "https://developer.shopline.com/docsv2/ec20/2c36f588a7f4ea1184a9af90b82f23be/05deddce13d2f580a5949cff1210dbad?version=v20250301"
                            }
                        ]
                    },
                    {
                        "title": "Order API",
                        "content": "Sync product information across all sales channels integrated with SHOPLINE.",
                        "btns": [
                            {
                                "title": "Get started",
                                "link": "https://developer.shopline.com/docsv2/ec20/cdb180dc069f7d8d6877c0cdffe96f73/JeIKq5Oi?version=v20250301"
                            }
                        ]
                    },
                    {
                        "title": "Inventory API",
                        "content": "Streamline inventory management and easily sync data across all channels integrated with SHOPLINE.",
                        "btns": [
                            {
                                "title": "Get started",
                                "link": "https://developer.shopline.com/docsv2/ec20/2c36f588a7f4ea1184a9af90b82f23be/e2ee104a5f5ae85b9f79d843085f52b9?version=v20250301"
                            }
                        ]
                    },
                    {
                        "title": "Abandoned Checkout API",
                        "content": "Manage and monitor checkout and order abandonment events with ease with this API.",
                        "btns": [
                            {
                                "title": "Get started",
                                "link": "https://developer.shopline.com/docsv2/ec20/cdb180dc069f7d8d6877c0cdffe96f73/hO79yrCM?version=v20250301"
                            }
                        ]
                    },
                    {
                        "title": "Carrier Service API",
                        "content": "Effortlessly create, connect and manage carrier services to provide localized shipping and fulfillment solutions for shoppers with a few simple endpoints.",
                        "btns": [
                            {
                                "title": "Get started",
                                "link": "https://developer.shopline.com/docsv2/ec20/cdb180dc069f7d8d6877c0cdffe96f73/MKlvTdMe?version=v20250301"
                            }
                        ]
                    }
                ]
            },
            "integrations": {
                "id": "6_2",
                "title": "Extensibility",
                "subtitle": "Apps & Integrations",
                "content": {
                    "content": "\"Meta builds technologies that help people connect, find communities, and grow businesses; a purpose closely aligned with that of SHOPLINE. With an established and close global partnership, we're excited to support SHOPLINE's continued growth. Their best-of-breed integration using Meta's latest APIs ensures consistent and accurate data synchronization, resulting in higher-performing ads for their customers.\"\n\n— Nadia Tan, Director of Global Marketing Solutions Partnerships APAC, Meta",
                    "pic": "93_Extensibility_1",
                    "btns": [
                        {
                            "title": "Visit app store",
                            "link": "https://apps.shopline.com/"
                        }
                    ]
                },
                "content2": [
                    {
                        "title": "Meta",
                        "content": "Connect your online store with Facebook Business Manager through SHOPLINE’s admin and share data directly to Facebook, ensuring maximum customer engagement.",
                        "pic": "Meta",
                        "btns": [
                            {
                                "title": "Install Meta Connector",
                                "link": "https://apps.shopline.com/detail/?appHandle=facebook"
                            }
                        ]
                    },
                    {
                        "title": "Buy with Prime (BWP)",
                        "content": "Provide customers with a more flexible shopping experience by enabling them to complete transactions via their Prime accounts, and take advantage of their membership benefits.",
                        "pic": "BWP",
                        "btns": [
                            {
                                "title": "Get in touch",
                                "link": "form"
                            }
                        ]
                    },
                    {
                        "title": "Klaviyo",
                        "content": "Enrich and enhance  your Klaviyo email and SMS campaigns with your SHOPLINE customer attributes, purchase history, behavioral data, and more.",
                        "pic": "Klaviyo",
                        "btns": [
                            {
                                "title": "Install Klaviyo",
                                "link": "https://apps.shopline.com/detail/?appHandle=klaviyo_youjian_smsyingxiao"
                            }
                        ]
                    },
                    {
                        "title": "Dotdigital",
                        "content": "Seamlessly unify, enrich, and segment customer data, between SHOPLINE and third party channels to create hyper-personalized marketing campaigns. ",
                        "pic": "Dotdigital",
                        "btns": [
                            {
                                "title": "Install Dotdigital",
                                "link": "https://apps.shopline.com/detail/?appHandle=dotdigital6001"
                            }
                        ]
                    },
                    {
                        "title": "LitExtension",
                        "content": "Simplify the migration process from over 140+ commerce platforms to SHOPLINE. Accurately and securely transfer all product information, customer data, orders and more in just hours.",
                        "pic": "Litextension",
                        "btns": [
                            {
                                "title": "Install LitExtension",
                                "link": "https://apps.shopline.com/detail/?appHandle=litextension_store_migration"
                            }
                        ]
                    },
                    {
                        "title": "Easyship",
                        "content": "The all in one global shipping solution — manage and automate all tax & duties, shipping logistics, carrier management, tracking information, and more across 250+ global carriers, including UPS, FedEx, USPS and DHL.",
                        "pic": "Easyship",
                        "btns": [
                            {
                                "title": "Install Easyship",
                                "link": "https://apps.shopline.com/detail/?appHandle=easyship_all_in_one_shipping"
                            }
                        ]
                    }
                ]
            }
        },
        "reaching_new_markets": {
            "id": "7",
            "title": "Global Selling",
            "subtitle": "SHOPLINE Markets",
            "content": {
                "content": "The integrated solution that enables you to expand, optimize and scale your business overseas, all from a single storefront. SHOPLINE Markets is equipped with comprehensive tools and features that simplify currency conversions, language settings, local tax configurations, shipping logistics and more. ",
                "pic": "94_SHOPLINE_Markets",
                "btns": [
                    {
                        "title": "Find out more",
                        "link": "https://www.shopline.com/global-ecommerce"
                    }
                ]
            },
            "content2": [
                {
                    "title": "Localized domain configuration",
                    "content": "Set up internal domain names and enable automated redirection based on user IP.",
                    "pic": "95_SHOPLINE_Markets_1",
                    "btns": [
                        {
                            "title": "Learn more",
                            "link": "https://help.shopline.com/hc/en-001/articles/13260695782425-Setting-Up-Unique-URLs-for-Global-Markets"
                        }
                    ]
                },
                {
                    "title": "Multi-currency payments",
                    "content": "Simplify global sales with localized pricing to attract international customers and boost conversion rates.",
                    "pic": "96_SHOPLINE_Markets_1",
                    "btns": [
                        {
                            "title": "Learn more",
                            "link": "https://help.shopline.com/hc/en-001/articles/29474901945881-Multi-currency-with-SHOPLINE-Payments"
                        }
                    ]
                },
                {
                    "title": "Price differentiation",
                    "content": "Implement localized pricing strategies with automated adjustments based on local currency exchange rates, fixed or percentage based adjustments, and more.",
                    "pic": "97_SHOPLINE_Markets_1",
                    "btns": [
                        {
                            "title": "Learn more",
                            "link": "https://help.shopline.com/hc/en-001/articles/12436359908889-Setting-Market-Specific-Products-and-Pricing"
                        }
                    ]
                },
                {
                    "title": "Region-specific promotions",
                    "content": "Create and pre-schedule localized campaigns and promotions targeting specific regional consumer behavior trends, holidays, seasonality and more.",
                    "pic": "98_SHOPLINE_Markets_1",
                    "btns": [
                        {
                            "title": "Learn more",
                            "link": "https://help.shopline.com/hc/en-001/articles/12437331967257-Discounts-and-Currencies"
                        }
                    ]
                }
            ],
            "content3": [
                {
                    "title": "Language localization",
                    "content": "Accurately translate content and manage native language settings with ease, for over 35 languages.",
                    "btns": [
                        {
                            "title": "Learn more",
                            "link": "https://help.shopline.com/hc/en-001/articles/13259908085017-Managing-Market-Languages-for-Your-Online-Store"
                        }
                    ]
                },
                {
                    "title": "Cross-border shipping",
                    "content": "Create region-specific shipping zones with preset delivery fees in local currencies, and leverage our integrated carriers to streamline local and global delivery logistics.",
                    "btns": [
                        {
                            "title": "Learn more",
                            "link": "https://help.shopline.com/hc/en-001/articles/4407135295769-Setting-Up-Your-SHOPLINE-Shipping-Profiles#h_01HVAQ1V5F9YTF3MQ2PGAZT6EZ"
                        }
                    ]
                },
                {
                    "title": "Global tax settings",
                    "content": "Easily configure tax rates for each region and determine the method of tax collection to ensure compliance with local regulations.",
                    "btns": [
                        {
                            "title": "Learn more",
                            "link": "https://help.shopline.com/hc/en-001/articles/900005250783-Setting-Up-and-Managing-Taxes-for-Your-SHOPLINE-Store#h_01HV0HWHNW5NABAFCV52E78ZEP"
                        }
                    ]
                }
            ]
        },
        "b2b": {
            "overview": {
                "id": "8_1",
                "title": "B2B & Wholesale",
                "subtitle": "SHOPLINE B2B",
                "content": {
                    "content": "Digitalize your wholesale business and tap into a global customer base. Our integrated B2B solution provides you with all the tools you need to reach and connect with customers, generate quotes, showcase products, receive orders and manage inventory all over the world, all from a single platform.",
                    "pic": "99_SHOPLINE_B2B_1",
                    "btns": [
                        {
                            "title": "Find out more",
                            "link": "https://www.shopline.com/b2b-ecommerce-platform"
                        }
                    ]
                },
                "content2": [
                    {
                        "title": "Company profiles",
                        "content": "Create company specific profiles to manage and provide all contacts with access to personalized product catalogs, payment terms, credit limits, and more.",
                        "pic": "100_SHOPLINE_B2B_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/21925057806745-B2B-Company#h_01HF39VVHZQ6K8GRZNMMWQKTMS"
                            }
                        ]
                    },
                    {
                        "title": "Personalized product catalogs",
                        "content": "Create personalized product catalogs with custom pricing and product assortments tailored to specific customers.",
                        "pic": "101_SHOPLINE_B2B_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/22404638139801-B2B-Catalog"
                            }
                        ]
                    },
                    {       
                        "title": "Pricing configurations",
                        "content": "Set up different pricing configurations based on tiers, purchase quantities, percentage-based adjustments, and more.",
                        "pic": "102_SHOPLINE_B2B_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/4415757807897-Tiered-Price"
                            }
                        ]
                    },
                    {
                        "title": "Payment and shipping settings",
                        "content": "Easily set up differentiated payment terms, credit limits, payment types and shipping options with a few clicks.",
                        "pic": "103_SHOPLINE_B2B_1",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/33276682558105-B2B-Payment-Method-Configuration"
                            }
                        ]
                    }
                ],
                "content3": [
                    {
                        "title": "Bulk product inquiry",
                        "content": "Enable customers to easily request a single quote for multiple products and in bulk.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/5213479919129-Bulk-Inquiry"
                            }
                        ]
                    },
                    {
                        "title": "Custom contact forms",
                        "content": "Create custom contact forms to capture essential business information and streamline communications with prospects.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/31622589741465-Allowing-Customers-to-Apply-for-a-B2B-Company-Account"
                            }
                        ]
                    },
                    {
                        "title": "Quick orders",
                        "content": "Enable a seamless bulk ordering process by enabling customers to search, add, and upload a products list (.csv), all from a single page.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/35072190153241-Enabling-Quick-Order-for-your-B2B-Customers-Early-Access"
                            }
                        ]
                    },
                    {
                        "title": "Re-order",
                        "content": "Enable customers to quickly repurchase previously ordered items with a single click or action, streamlining large volume B2B transactions.",
                        "btns": [
                            {
                                "title": "Learn more",
                                "link": "https://help.shopline.com/hc/en-001/articles/33276682558105-B2B-Payment-Method-Configuration"
                            }
                        ]
                    }
                ]
            },
            "merchant_interview__kayser_roth": {
                "id": "8_2",
                "title": "Kayser-Roth Corporation: Leading Apparel Manufacturer",
                "subtitle": "Merchant Spotlight: Kayser-Roth",
                "content": "Discover why this renowned American hosiery and apparel manufacturer trusted SHOPLINE to power their B2B commerce transformation.",
                "pic": "104_SHOPLINE_B2B_1",
                "v1": "Fashion_en.webm",
                "v2": "",
                "btns": [
                    {
                        "title": "Find out more",
                        "link": "https://www.shopline.com/b2b-ecommerce-platform"
                    }
                ]
            }
        },
        "forms": {
            "title": "Expand your\nbusiness horizons\nwith SHOPLINE",
            "title2": "What SHOPLINE feature would you like to explore further? (Select all that apply)",
            "title3": "By proceeding, you agree that your personal data will be collected for communication purposes only and will not be shared with other third parties. For more details, please refer to our [Privacy Policy](https://www.shopline.com/privacy).",
            "fields": {
                "Name_First": "First Name",
                "Name_Last": "Last Name",
                "Email": "* Business Email Address",
                "Company": "* Company Name",
                "Annual_Sales": "Annual Sales",
                "Phone_Number": "Phone Number",
                "Country": "Country / Region",
                "Role": "* Role"
            },
            "success": "We’ve received your information and will be in touch shortly."
        }
    }
}
export default site_data;