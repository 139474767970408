import React, { useEffect, useState, useRef, memo } from 'react';
import { parseContent } from '../utils';
import Button from './Button';

function TextSeg(props) {
  const { title, content, btns } = props;
  return (
    <div className='seg_text'>
        {title?<h4>{title}</h4>:null}
        <p>{parseContent(content)}</p>
        {(btns||[]).map((btn, index) => {
            return <Button key={index} {...btn}/>
        })}
    </div>
  );
}

export default TextSeg;
