import React, { useEffect, useState, useRef, memo } from 'react';
import { events } from '../utils';
/* 
  <video 
  crossorigin="anonymous" 
  poster="https://fast.wistia.com/assets/images/blank.gif" 
  aria-label="Video" 
  src="" 
  controlslist="nodownload" 
  playsinline="" 
  preload="none" x-webkit-airplay="allow"></video>
*/
function VideoView(props) {
    const { preViewSrc, src } = props;
    const previewRef = useRef(null);
    const btnRef = useRef(null);
    const [ isImg, setIsImg ] = useState(preViewSrc.indexOf('.webp') > -1);
    // <div className='sec_image' style={{backgroundImage: `url("${STATIC_PATH}${reaching_new_markets.content.pic}.webp")`}}></div>
    const [ strokeData, setStrokeData ] = useState(0);
    const onResize = () => {
        if (btnRef.current) {
            const radius = btnRef.current.offsetWidth/2; // 圆的半径
            const circumference = 2 * Math.PI * radius;
            setStrokeData(circumference);
        }
    }
    const onCloseVideo = () => {
        !isImg&&previewRef.current.play();
    }
    const showVideo = () => {
        !isImg&&previewRef.current.pause();
        events.emit('openVideo', src);
    }
    useEffect(() => {
        onResize();
        events.addListener('resize', onResize);
        events.addListener('closeVideo', onCloseVideo);
        !isImg&&previewRef.current.play();
        return () => {
            events.removeListener('resize', onResize);
            events.removeListener('closeVideo', onCloseVideo);
        }
    }, []);
  return (
    <div className={'video_view ' + (isImg?'video_pic':'')}>
        <div className='video_play_btn'>
            <div className='video_play_btn_wrap' ref={btnRef} onClick={showVideo}>
                <div className='video_play_arr'>
                    <div><span><b></b><b></b><b></b></span></div>
                </div>
                <svg className="svg-circle-border" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <circle cx="50" cy="50" r="48" style={{
                        strokeDasharray: strokeData,
                        strokeDashoffset: strokeData
                    }} />
                </svg>
                <svg className="svg-circle-border1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <circle cx="50" cy="50" r="48" style={{
                        strokeDasharray: strokeData,
                        strokeDashoffset: 0
                    }} />
                </svg>
            </div>
        </div>
        {!isImg?<video ref={previewRef} playsInline muted loop src={preViewSrc}></video>:<div className='sec_image' style={{backgroundImage: `url("${preViewSrc}")`}}></div>}
    </div>
  );
}

export default VideoView;
