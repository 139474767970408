// export const HOST = 'http://localhost';
export const HOST = 'https://test.dinglei.io';
// export const HOST = 'https://horizons-test.myshopline.com';
export const STATIC_PATH = HOST + '/sh_line/pics/';
const _events = {};
export const events = {
  addListener: (type, fn) => {
    if (Array.isArray(_events[type])) {
      for (let i = 0; i < _events[type].length; i++) {
        if (_events[type][i] === fn) {
          return;
        }
      }
      _events[type].push(fn)
    } else {
      _events[type] = [fn]
    }
  },
  emit: (type, ...args) => {
    if (Array.isArray(_events[type])) {
      for (let i = 0; i < _events[type].length; i++) {
        _events[type][i](...args)
      }
    }
  },
  removeListener: (type, fn) => {
    if (Array.isArray(_events[type])) {
      for (let i = 0; i < _events[type].length; i++) {
        if (_events[type][i] === fn) {
          _events[type].splice(i, 1);
          if (_events[type].length === 0) {
            _events[type] = null;
            delete _events[type]
          }
          break;
        }
      }
    }
  }
}

export const parseContent = (str) => {
  // 正则表达式匹配 [link text](url) 格式
  const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
  // 正则表达式匹配 [\\disc] 格式
  const discRegex = /\[\\disc\]/g;

  // 按换行符 `\n` 分割
  const parts = str.split(/\n/);

  // 处理每一段
  return parts.map((part, index) => {
    const elements = [];
    let lastIndex = 0;
    let match;

    // 用正则替换 [link text](url) 为 JSX <a> 标签
    while ((match = linkRegex.exec(part)) !== null) {
      const [fullMatch, linkText, url] = match;

      // 添加链接之前的普通文本并处理 `[\\disc]`
      let textWithDisc = part.substring(lastIndex, match.index).split(discRegex);
      textWithDisc.forEach((text, i) => {
        elements.push(text);
        if (i < textWithDisc.length - 1) {
          elements.push(<i key={`disc-${index}-${i}`} />);
        }
      });

      // 添加 <a> 标签
      elements.push(
        <a key={`link-${index}-${match.index}`} href={url} target="_blank" rel="noopener noreferrer">
          {linkText}
        </a>
      );

      lastIndex = linkRegex.lastIndex;
    }

    // 处理最后的普通文本和 `[\\disc]`
    let remainingText = part.substring(lastIndex).split(discRegex);
    remainingText.forEach((text, i) => {
      elements.push(text);
      if (i < remainingText.length - 1) {
        elements.push(<i key={`disc-end-${index}-${i}`} />);
      }
    });

    // 用 JSX 包裹每一行，添加 <br /> 实现换行
    return [elements, index < parts.length - 1 && <br />];
  });
}

// 封装一个函数节流的方法
export const throttle = (fn, delay) => {
  let last = 0;
  return function (...args) {
    let now = +new Date();
    if (now - last > delay) {
      last = now;
      fn.apply(this, args);
    }
  }
}

// 封装一个函数防抖的方法
export const debounce = (fn, delay) => {
  let timer = null;
  return function (...args) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  }
}

export const scrollTo = (targetY, duration=600) => {
  if (duration === 0) {
    window.scrollTo(0, targetY);
    return;
  }
  const startY = window.scrollY;
  const distance = targetY - startY;
  let startTime = null;

  const animateScroll = (currentTime) => {
    if (!startTime) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const progress = Math.min(timeElapsed / duration, 1);
    window.scrollTo(0, startY + distance * easeInOutQuad(progress));

    if (timeElapsed < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      window.scrollTo(0, targetY);
    }
  };

  // 缓动函数
  const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2);

  requestAnimationFrame(animateScroll);
};


// 封装一个获取某一个dom元素到上层指定dom元素的位置
export const getOffset = (el, parent=document.body) => {
  let offset = {
    left: 0,
    top: 0
  }
  while (el && el !== parent) {
    offset.left += el.offsetLeft;
    offset.top += el.offsetTop;
    el = el.offsetParent;
  }
  return offset;
}